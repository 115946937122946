export const GET_BRAND_USER = 'brand-account/get-brand-user';
export const GET_BRAND_USER_SUCCESS = 'brand-account/get-brand-user-success';
export const GET_BRAND_USER_ERROR = 'brand-account/get-brand-user-error';

export const GET_BRAND_PACKAGE = 'brand-account/get-brand-package';
export const GET_BRAND_PACKAGE_SUCCESS =
  'brand-account/get-brand-package-success';
export const GET_BRAND_PACKAGE_ERROR = 'brand-account/get-brand-package-error';

export const SEND_REQUEST = 'brand-account/send-request';
export const SEND_REQUEST_SUCCESS = 'brand-account/send-request-success';
export const SEND_REQUEST_ERROR = 'brand-account/send-request-error';

export const SEND_REACH_BOOST_REQUEST =
  'brand-account/send-reach-boost-request';
export const SEND_REACH_BOOST_REQUEST_SUCCESS =
  'brand-account/send-reach-boost-request-success';
export const SEND_REACH_BOOST_REQUEST_ERROR =
  'brand-account/send-reach-boost-request-error';

export const CLEAR_STATE = 'brand-account/clear-state';
