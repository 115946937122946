import {
  GET_CONSUMER,
  GET_CONSUMER_SUCCESS,
  GET_CONSUMER_ERROR,
  CONSUMER_CLEAR,
  CLEAR_CONSUMER_ERROR_MESSAGES,
  EDIT_CONSUMER,
  EDIT_CONSUMER_SUCCESS,
  EDIT_CONSUMER_ERROR,
  EDIT_CONSUMER_SUBSCRIPTION,
  EDIT_CONSUMER_SUBSCRIPTION_SUCCESS,
  EDIT_CONSUMER_SUBSCRIPTION_ERROR,
} from './ConsumerFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONSUMER:
      return {
        ...state,
        data: null,
        loading: true,
        error: null,
        success: null,
      };

    case GET_CONSUMER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_CONSUMER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CONSUMER_CLEAR:
      return { ...INITIAL_STATE };

    case CLEAR_CONSUMER_ERROR_MESSAGES:
      return {
        ...state,
        success: null,
        error: null,
      };

    case EDIT_CONSUMER:
      return {
        ...state,
        loading: true,
        createSuccess: false,
        editSuccess: false,
      };

    case EDIT_CONSUMER_SUCCESS:
      return {
        ...state,
        loading: false,
        editSuccess: true,
        data: action.payload,
      };

    case EDIT_CONSUMER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EDIT_CONSUMER_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
        createSuccess: false,
        editSuccess: false,
      };
    case EDIT_CONSUMER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        editSuccess: true,
        data: action.payload,
      };
    case EDIT_CONSUMER_SUBSCRIPTION_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
