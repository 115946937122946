import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Switch } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { ThemeProvider } from '@material-ui/styles';

import { configureStore } from './store';
import * as serviceWorker from './serviceWorker';
import { PublicLayout, AdminLayout } from './layouts';
import { AppTheme } from './theme/theme';

import './i18n';

const browserHistory = createBrowserHistory();
const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={AppTheme}>
        <Router history={browserHistory}>
          <Switch>
            <AdminLayout path="/dashboard/" />
            <PublicLayout path="/" />
          </Switch>
        </Router>
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
