import React from 'react';
import { Grid, Typography, InputLabel } from '@material-ui/core';

export const ReadOnlyTextItem = ({
  label,
  content,
  itemClassName = 'read-only-text-item',
  labelClassName = 'MuiInputLabel-shrink',
  size,
}) => {
  return (
    <Grid item className={itemClassName} {...size}>
      <InputLabel className={labelClassName}>{label}</InputLabel>
      <Typography>{content}</Typography>
    </Grid>
  );
};
