import React from 'react';
import { Controller } from 'react-hook-form';

import { RadioGroup } from '@material-ui/core';

interface RadioButtonGroupProps {
  control: any;
  children?: any;
  rules?: any;
  name: string;
  handleOnChange?: any;
}

export const RadioButtonGroup = ({
  control,
  children,
  rules,
  name,
  handleOnChange,
}: RadioButtonGroupProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ onChange, value }) => (
        <RadioGroup
          value={value}
          onChange={(event) => {
            onChange(event);
            handleOnChange?.(event);
          }}
          row
        >
          {children}
        </RadioGroup>
      )}
    />
  );
};
