export const COUPON_START = 'coupon/coupon-start';
export const COUPON_SUCCESS = 'coupon/coupon-success';
export const COUPON_ERROR = 'coupon/coupon-error';

export const GET_COUPONS = 'coupons/start';
export const GET_COUPONS_SUCCESS = 'coupons/success';
export const GET_COUPONS_ERROR = 'coupons/error';

export const GET_COUPONS_AS_BRAND_ADMIN = 'brand-admin/coupons/start';
export const GET_COUPONS_AS_BRAND_ADMIN_SUCCESS = 'brand-admin/coupons/success';
export const GET_COUPONS_AS_BRAND_ADMIN_ERROR = 'brand-admin/coupons/error';

export const DELETE_COUPON = 'coupon/delete';
export const DELETE_COUPON_SUCCESS = 'coupon/delete_success';
export const DELETE_COUPON_ERROR = 'coupon/delete_error';

export const COUPONS_PAGINATION = 'coupons/pagination';
export const CHANGE_PAGINATION = 'coupon/change-pagination';
export const CLEAR_COUPON = 'coupon/clear-coupon';
