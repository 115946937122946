import * as headImgActionTypes from './HeaderImagesActionTypes';

const pagesWithHeaderImg = [
  'ABOUT',
  'IMPRESSUM',
  'PRIVACY_POLICY',
  'TERMS_AND_CONDITIONS',
  'FAQ',
];

const generateInitialData = (pages) =>
  pages.map((page: string) => ({
    pageName: page,
  }));

const INITIAL_STATE = {
  error: null,
  loading: false,
  pages: [],
  uploadErrors: {},
  uploadLoading: {},
};

export default (
  state = { ...INITIAL_STATE, pages: generateInitialData(pagesWithHeaderImg) },
  action
) => {
  switch (action.type) {
    case headImgActionTypes.GET_HEAD_IMG_LIST:
      return {
        ...state,
        loading: true,
      };

    case headImgActionTypes.GET_HEAD_IMG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pages: state.pages.map((page: any) => {
          const indexPage = action.payload.data.findIndex(
            (pageRes: any) => pageRes.pageName === page.pageName
          );
          if (indexPage !== -1) {
            return {
              ...action.payload.data[indexPage],
              pageName: page.pageName,
            };
          } else {
            return page;
          }
        }),
      };

    case headImgActionTypes.GET_HEAD_IMG_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case headImgActionTypes.UPLOAD_HEAD_IMG:
      return {
        ...state,
        uploadLoading: { ...state.uploadLoading, [action.payload]: true },
      };

    case headImgActionTypes.UPLOAD_HEAD_IMG_SUCCESS:
      return {
        ...state,
        pages: state.pages.map((header: any) => {
          if (header.pageName === action.payload.page) {
            return { ...header, imageLink: action.payload.data.fileName };
          } else {
            return header;
          }
        }),
        //@ts-ignore
        uploadLoading: (({ [action.payload.page]: pageName, ...pageLoading }) =>
          pageLoading)(state.uploadLoading),
        //@ts-ignore
        uploadErrors: (({ [action.payload.page]: pageName, ...pageLoading }) =>
          pageLoading)(state.uploadErrors),
      };

    case headImgActionTypes.UPLOAD_HEAD_IMG_ERROR:
      return {
        ...state,
        //@ts-ignore
        uploadLoading: (({ [action.payload.page]: pageName, ...pageLoading }) =>
          pageLoading)(state.uploadLoading),
        uploadErrors: {
          ...state.uploadErrors,
          [action.payload.page]: action.payload.error,
        },
      };

    default:
      return state;
  }
};
