export const saveUserToken = (token, rememberMe = false) => {
  if (rememberMe) {
    localStorage.setItem('token', token);
  } else {
    sessionStorage.setItem('token', token);
  }
};

export const logoutUser = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('account');
  sessionStorage.removeItem('token');
};

export const isAuth = () => {
  return localStorage.getItem('token') || sessionStorage.getItem('token');
};
