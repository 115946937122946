import { getLocalAccount } from './account';
import { ROLES, ROLE_MAP } from './permissions';

export const isAbleTo = (namespace: string, action: string) => {
  const localUser = getLocalAccount();
  let ableArr: any = [];

  if (!localUser || !localUser.authorities) {
    return false;
  }

  (localUser.authorities || []).forEach((authority) => {
    if (
      ROLE_MAP[authority.name] &&
      ROLE_MAP[authority.name][namespace] &&
      ROLE_MAP[authority.name][namespace][action]
    ) {
      ableArr.push(ROLE_MAP[authority.name][namespace][action]);
    }
  });

  let isAble = false;
  ableArr.forEach((able) => {
    if (able) isAble = true;
  });

  return isAble;
};

export const isSuperAdmin = () => {
  const localUser = getLocalAccount();

  if (!localUser || !localUser.authorities) {
    return false;
  }

  let isSuperAdmin = false;
  (localUser.authorities || []).forEach((authority) => {
    if (authority.name === ROLES.SUPERADMIN) {
      isSuperAdmin = true;
    }
  });

  return isSuperAdmin;
}

export const isBrandAdmin = () => {
  const localUser = getLocalAccount();

  if (!localUser || !localUser.authorities) {
    return false;
  }

  let isBrandAdmin = false;
  (localUser.authorities || []).forEach((authority) => {
    if (authority.name === ROLES.BRAND_ADMIN) {
      isBrandAdmin = true;
    }
  });

  return isBrandAdmin;
}
