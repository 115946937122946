import React from 'react';

import { Typography, IconButton, DialogTitle } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import './CustomDialog.scss';

interface CustomDialogTitleProps {
  showCloseButton?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

export const CustomDialogTitle = ({
  showCloseButton = false,
  children,
  onClose,
  ...props
}: CustomDialogTitleProps) => {
  return (
    <DialogTitle
      disableTypography
      id="customized-dialog-title"
      {...props}
      className="custom-dialog-title"
    >
      <Typography variant="h6">{children}</Typography>
      {showCloseButton ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="x-close-button"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
