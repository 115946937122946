export const BRAND_PAGES_START = 'brand/brand-start';
export const BRAND_PAGES_SUCCESS = 'brand/brand-success';
export const BRAND_PAGES_ERROR = 'brand/brand-error';

export const GET_BRAND_PAGES = 'brand/start';
export const GET_BRAND_PAGES_SUCCESS = 'brand/success';
export const GET_BRAND_PAGES_ERROR = 'brand/error';
export const BRAND_PAGES_PAGINATION = 'brand/pagination';

export const DELETE_BRAND_PAGE = 'brand/delete';
export const DELETE_BRAND_PAGE_SUCCESS = 'brand/delete_success';
export const DELETE_BRAND_PAGE_ERROR = 'brand/delete_error';

export const GET_ALL_BRAND_PAGES_MAP = 'brand/getAllMap';
export const GET_ALL_BRAND_PAGES_MAP_SUCCESS = 'brand/getAllMapSuccess';
export const GET_ALL_BRAND_PAGES_MAP_ERROR = 'brand/getAllMapError';

export const CHANGE_PAGINATION = 'brand/change-pagination';
