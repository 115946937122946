import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as AccountService from 'services/account';
import { setAccount } from 'containers/Account/AccountActions';

export const Account = () => {
  const dispatch = useDispatch();
  const userAccount = AccountService.getLocalAccount();

  useEffect(() => {
    dispatch(setAccount(userAccount));
  }, [dispatch, userAccount]);

  return null;
};
