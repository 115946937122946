export const PRODUCT_START = 'product/product-start';
export const PRODUCT_SUCCESS = 'product/product-success';
export const PRODUCT_ERROR = 'product/product-error';

export const GET_PRODUCTS = 'products/start';
export const GET_PRODUCTS_SUCCESS = 'products/success';
export const GET_PRODUCTS_ERROR = 'products/error';

export const SET_PRODUCT_FEATURED_START = 'products/set-featured-start';
export const SET_PRODUCT_FEATURED_SUCCESS = 'products/set-featured-success';
export const SET_PRODUCT_FEATURED_ERROR = 'products/set-featured-error';
export const MARK_PRODUCT_FEATURED = 'products/mark-featured';
export const SET_PRODUCT_NOT_FEATURED_SUCCESS =
  'products/set-not-featured-success';

export const DELETE_PRODUCT = 'product/delete';
export const DELETE_PRODUCT_SUCCESS = 'product/delete_success';
export const DELETE_PRODUCT_ERROR = 'product/delete_error';

export const CLEAR_PRODUCT = 'product/clear-product';
export const CHANGE_FILTER = 'product/change-filter';

export const GET_ELIGIBLE_PRODUCTS = 'products/eligible/start';
export const GET_ELIGIBLE_PRODUCTS_SUCCESS = 'products/eligible/success';
export const GET_ELIGIBLE_PRODUCTS_ERROR = 'products/eligible/error';
