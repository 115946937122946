import { UserProfile } from 'components';
import {
  USER_START,
  USER_SUCCESS,
  USER_ERROR,
  USER_PAGINATION,
  GET_USERS,
  GET_USERS_SUCCESS,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  GET_USERS_ERROR,
  CHANGE_PAGINATION,
  CLEAR_USERS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
} from './UserTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CHANGE_PAGINATION:
      return { ...state, filter: action.payload };

    case USER_START:
      return { ...state, loading: true, error: null };

    case USER_SUCCESS:
      return { ...state, loading: false };

    case USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_USERS:
      return {
        ...state,
        loading: true,
      };

    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case DELETE_USER_ERROR:
      return {
        ...state,
        loading: false,
        deleteError: true,
        error: action.payload,
      };

    case GET_USERS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DELETE_USER_SUCCESS:
      return { ...state, loading: false, deleteSuccess: true };

    case USER_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };

    case CLEAR_USERS: {
      return {userProfile: state.userProfile, ...INITIAL_STATE};
    }

    case GET_USER_PROFILE:
      return { ...state, loading: true };
    
    case GET_USER_PROFILE_SUCCESS:
      return { ...state, loading: false, userProfile: action.payload };

    case GET_USER_PROFILE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
