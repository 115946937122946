export const CREATE_COUPON = 'coupons-form/create-coupon';
export const CREATE_COUPON_SUCCESS = 'coupons-form/create-coupon-success';
export const CREATE_COUPON_ERROR = 'coupons-form/create-coupon-error';

export const EDIT_COUPON = 'coupons-form/edit-coupons';
export const EDIT_COUPON_SUCCESS = 'coupons-form/edit-coupons-success';
export const EDIT_COUPON_ERROR = 'coupons-form/edit-coupons-error';

export const GET_COUPONS = 'coupons-form/get-coupons';
export const GET_COUPONS_SUCCESS = 'coupons-form/get-coupons-success';
export const GET_COUPONS_ERROR = 'coupons-form/get-coupons-error';

export const CLEAR_COUPON = 'coupons-form/clear-coupon';
export const CLEAR_COUPON_SUCCESS = 'coupons-form/clear-coupon-success';

export const DELETE_MEDIA = 'coupons-form/delete-media';
export const DELETE_MEDIA_SUCCESS = 'coupons-form/delete-media-success';
export const DELETE_MEDIA_ERROR = 'coupons-form/delete-media-error';

export const CLEAR_UPLOAD_DATA = 'coupons-form/clear-data';
export const CLEAR_COUPON_ERROR_MESSAGES = 'coupons-form/clear-err-messages';
