import {
  Login,
  NotFound,
  ForgotPassword,
  SetPassword,
  InvalidToken,
} from 'containers';

export const PublicRoutes = [
  {
    component: Login,
    path: 'login',
  },
  {
    component: ForgotPassword,
    path: 'forgot-password',
  },
  {
    component: SetPassword,
    path: 'register/:username/:signUptoken',
  },
  {
    component: SetPassword,
    path: 'new-password/:username/:changePasswordToken',
  },
  {
    component: InvalidToken,
    path: 'invalid-token/:reason',
  },
  {
    component: NotFound,
    path: '*',
  },
];
