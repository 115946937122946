import {
  CLEAR_COUPON,
  CLEAR_COUPON_SUCCESS,
  CREATE_COUPON,
  CREATE_COUPON_ERROR,
  CREATE_COUPON_SUCCESS,
  EDIT_COUPON,
  EDIT_COUPON_ERROR,
  EDIT_COUPON_SUCCESS,
  GET_COUPONS,
  GET_COUPONS_ERROR,
  GET_COUPONS_SUCCESS,
  DELETE_MEDIA,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_ERROR,
  CLEAR_COUPON_ERROR_MESSAGES,
} from './CouponFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  uploadData: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_MEDIA:
      return {
        ...state,
        loading: true,
      };

    case DELETE_MEDIA_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case DELETE_MEDIA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CREATE_COUPON:
      return {
        ...state,
        loading: true,
        createSuccess: false,
        editSuccess: false,
      };

    case CREATE_COUPON_SUCCESS:
      return { ...state, loading: false, createSuccess: true };

    case CREATE_COUPON_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EDIT_COUPON:
      return {
        ...state,
        loading: true,
        createSuccess: false,
        editSuccess: false,
      };

    case EDIT_COUPON_SUCCESS:
      return { ...state, loading: false, editSuccess: true };

    case EDIT_COUPON_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_COUPONS:
      return { ...state, loading: true };

    case GET_COUPONS_SUCCESS:
      return { ...state, loading: false, activeCoupon: action.payload };

    case GET_COUPONS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CLEAR_COUPON:
      return {
        ...INITIAL_STATE,
      };

    case CLEAR_COUPON_SUCCESS:
      return {
        ...state,
        success: null,
      };

    case CLEAR_COUPON_ERROR_MESSAGES:
      return INITIAL_STATE;

    default:
      return state;
  }
};
