import {
  CONSUMER_START,
  CONSUMER_SUCCESS,
  CONSUMER_ERROR,
  GET_CONSUMERS,
  DELETE_CONSUMER_ERROR,
  GET_CONSUMERS_SUCCESS,
  GET_CONSUMERS_ERROR,
  DELETE_CONSUMER_SUCCESS,
  CONSUMER_PAGINATION,
  EXPORT_CONSUMERS,
  EXPORT_CONSUMERS_ERROR,
  EXPORT_CONSUMERS_SUCCESS,
  CHANGE_PAGINATION,
  CLEAR_CONSUMER,
} from './ConsumerTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CHANGE_PAGINATION:
      return { ...state, filter: action.payload };

    case CONSUMER_START:
      return { ...state, loading: true, error: null };

    case CONSUMER_SUCCESS:
      return { ...state, loading: false };

    case CONSUMER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_CONSUMERS:
      return {
        ...state,
        loading: true,
      };

    case GET_CONSUMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case DELETE_CONSUMER_ERROR:
      return {
        ...state,
        loading: false,
        deleteError: true,
        error: action.payload,
      };

    case GET_CONSUMERS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case DELETE_CONSUMER_SUCCESS:
      return { ...state, loading: false, deleteSuccess: true };

    case CONSUMER_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };

    case EXPORT_CONSUMERS:
      return {
        ...state,
        loading: true,
      };

    case EXPORT_CONSUMERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case EXPORT_CONSUMERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_CONSUMER:
      return INITIAL_STATE;

    default:
      return state;
  }
};
