import * as productCodeActionTypes from './ProductCodesTypes';

interface StateType {
  error: null | any;
  loading: boolean;
  fileName: null | string;
  uploadedCode: boolean;
  product: any;
  data: any;
  filter?: any;
  total: number;
}

const INITIAL_STATE: StateType = {
  error: null,
  loading: false,
  fileName: null,
  uploadedCode: false,
  product: null,
  data: [],
  filter: {
    pageSize: 10,
    page: 0,
    sort: {
      field: 'id',
      dir: 'DESC',
    },
    search: '',
  },
  total: 0,
};

export default (state: StateType = INITIAL_STATE, action): StateType => {
  switch (action.type) {
    case productCodeActionTypes.UPLOAD_CODE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case productCodeActionTypes.UPLOAD_CODE_SUCCESS:
      return { ...state, loading: false, uploadedCode: true };
    case productCodeActionTypes.UPLOAD_CODE_ERROR:
      return { ...state, loading: false, error: action.payload };
    case productCodeActionTypes.UPLOAD_CODES_CSV:
      return { ...state, loading: true, fileName: null };
    case productCodeActionTypes.UPLOAD_CODES_CSV_SUCCESS:
      return { ...state, loading: false, fileName: action.payload };
    case productCodeActionTypes.UPLOAD_CODES_CSV_ERROR:
      return { ...state, loading: false, error: action.payload };
    case productCodeActionTypes.GET_PRODUCT:
      return { ...state, loading: true, error: false };
    case productCodeActionTypes.GET_PRODUCT_SUCCESS:
      return { ...state, loading: false, product: action.payload };
    case productCodeActionTypes.GET_PRODUCT_ERROR:
      return { ...state, loading: false, error: action.payload };
    case productCodeActionTypes.RESET_PRODUCT:
      return { ...state, product: null, uploadedCode: false, fileName: null };

    case productCodeActionTypes.CHANGE_FILTER:
      return { ...state, filter: action.payload };

    case productCodeActionTypes.GET_PRODUCT_SUBSCRIPTION_CODES:
      return { ...state, loading: true, error: null };

    case productCodeActionTypes.GET_PRODUCT_SUBSCRIPTION_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        total: action.payload.total,
      };

    case productCodeActionTypes.GET_PRODUCT_SUBSCRIPTION_CODES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case productCodeActionTypes.RESET_PRODUCT_SUBSCRIPTION_CODES:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};
