import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { Hidden } from '@material-ui/core';
import { Arrow, BlockedInfoDialog } from 'components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as RoleManager from 'services/role';

import './UserProfile.scss';
import { getUserProfile } from 'containers/User/UserActions';

export const UserProfile = () => {

  const location = useLocation();
  const currentPath = location.pathname;

  const dispatch = useDispatch();
  const account = useSelector((state: any) => state.account);
  const [open, setOpen] = React.useState(false);
  const anchorRef: any = React.useRef();
  const { t }: { t: any } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current !== undefined &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  let name = 'John Doe';
  let role = '';

  if (account?.user) {
    name = account.user.email;

    if (account.user.firstName) {
      name = `${account.user.firstName} ${account.user.lastName}`;
    }
  }

  if (account?.user?.authorities[0]) {
    const fullRole = account.user.authorities[0].name;
    role = fullRole
      .split('_')
      .map((r) => r.toLowerCase())
      .join(' ');
  }

  const userProfile = useSelector((state: any) => state.user?.userProfile);
  const isBrandAdmin = RoleManager.isBrandAdmin()

  const [showInfoDialogue, setShowInfoDialogue] = React.useState(false);

  const redirectToStripe = () => {
    if(userProfile?.stripeSessionUrl) {
      dispatch(getUserProfile());
      window.open(userProfile.stripeSessionUrl, '_blank');
    } else {
      setShowInfoDialogue(true);
    }
  }

  const closeDialog = () => {
    setShowInfoDialogue(false);
  }

  return (
    <>
      <Button className="btn-profile" ref={anchorRef} onClick={handleToggle}>
        <span className="profile-wrap">
          <div className="profile-name">{name}</div>
          <Hidden xsDown>
            <div className="profile-type">{role}</div>
          </Hidden>
        </span>
        <span className="arrow">
          <Arrow />
        </span>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className="user-dropdown"
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'bottom-end',
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {isBrandAdmin && (
                    <MenuItem>
                      <Link onClick={redirectToStripe} to={currentPath}>{t('manageTarif')}</Link>
                    </MenuItem>
                  )}
                  <MenuItem>
                    <Link to="/dashboard/logout">{t('logout')}</Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {showInfoDialogue && <BlockedInfoDialog messageType='stripeUserBlocked' allowClose={true} onClose={closeDialog} />}
    </>
  );
};
