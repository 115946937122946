import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Dialog.scss'
import { Box } from '@material-ui/core';

class BlockedInfoDialogProps {
  messageType: string = '';
  allowClose: boolean = true;
  onClose?: Function = () => {}
}


export const BlockedInfoDialog = ({ messageType, allowClose = true, onClose = () => {} }: BlockedInfoDialogProps) => {
  const { t }: { t: any } = useTranslation();
  const userProfile = useSelector((state: any) => state.user?.userProfile);

  function openMailToRecipient() {
    var recipientEmail = "customersuccess@traffico.de";
    window.open('mailto:' + recipientEmail);
  }

  const blockedMessages = {
    'userBlocked': t('blockedMessage'),
    'stripeUserBlocked': t('stripeBlockedMessage'),
    'noActiveSubscription': t('noActiveSubscriptionMessage'),
  }

  const closeDialog = () => {
    if (allowClose) {
      onClose();
    }
  }

  const redirectToStripe = () => {
    if(userProfile?.stripeSessionUrl) {
      // dispatch(getUserProfile());
      window.open(userProfile.stripeSessionUrl, '_blank');
    }
  }

  return (
    <Dialog
     open={true}
     onClose={closeDialog}
     PaperProps={{
      style: {
        borderRadius: '10px', // Set the border radius here
      },
    }}
    >
      <DialogContent>
        <Box paddingBottom={2}>
          <Typography
            style={{ fontSize: '16px', fontWeight: 500 }}
          >
            <p>{blockedMessages[messageType]}</p>
          </Typography>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 500 }}
            >
              <a href="mailto:customersuccess@traffico.de" style={{ color: 'black' }}>customersuccess@traffico.de</a>
              <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                +49 (0)30 30 80 60 84
              </p>
            </Typography>

            <Button
                style={{ width: '100px' }}
                color="default"
                variant="contained"
                autoFocus
                className='h-37 black-button'
                onClick={openMailToRecipient}
              >
              {t('email')}
            </Button>
          </Box>
          <Box mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 500 }}
            >
              <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                {t('noActiveSubscriptionMessage')}
              </p>
            </Typography>

            <Button
                style={{ width: '100px' }}
                color="default"
                variant="contained"
                autoFocus
                className='h-37 black-button'
                onClick={redirectToStripe}
              >
              {t('manage')}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
