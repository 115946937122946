import React from 'react';
import { Paper } from '@material-ui/core';

import './BoxWrap.scss';

type BoxWrapProps = {
  children?: any;
};

export const BoxWrap = ({ children }) => {
  return <Paper className="box-wrap">{children}</Paper>;
};

BoxWrap.Toolbar = ({ children }) => {
  return <div className="box-toolbar">{children}</div>;
};
