import React, { useState, useCallback } from 'react';

export const APIErrorContext = React.createContext({
  error: null,
  addMessage: () => {},
  removeError: () => {},
});

export default function APIErrorProvider({ children }) {
  const [error, setError] = useState(null);

  const removeError = () => {
    setError(null);
  };

  const addMessage = (message, status) => {
    return setError({ message, status });
  };

  const contextValue = {
    error,
    addMessage: useCallback(
      (message, status) => addMessage(message, status),
      []
    ),
    removeError: useCallback(() => removeError(), []),
  };

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  );
}
