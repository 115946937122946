import {
  CLEAR_CATEGORY,
  CLEAR_CATEGORY_SUCCESS,
  CREATE_CATEGORY,
  CREATE_CATEGORY_ERROR,
  CREATE_CATEGORY_SUCCESS,
  EDIT_CATEGORY,
  EDIT_CATEGORY_ERROR,
  EDIT_CATEGORY_SUCCESS,
  GET_CATEGORY,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_SUCCESS,
  CLEAR_CATEGORY_ERROR_MESSAGES,
  UPLOAD_SUCCESS,
  START_UPLOAD,
  UPLOAD_ERROR,
  DELETE_MEDIA,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_ERROR,
} from './CategoryFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  activeCategory: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_MEDIA:
      return {
        ...state,
        loading: true,
      };

    case DELETE_MEDIA_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case DELETE_MEDIA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case START_UPLOAD:
      return {
        ...state,
        loading: true,
      };

    case UPLOAD_SUCCESS:
      return {
        ...state,
        uploadData: action.payload,
        error: null,
        loading: false,
      };

    case UPLOAD_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CREATE_CATEGORY:
      return { ...state, loading: true };

    case CREATE_CATEGORY_SUCCESS:
      return { ...state, loading: false, success: true };

    case CREATE_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EDIT_CATEGORY:
      return { ...state, loading: true };

    case EDIT_CATEGORY_SUCCESS:
      return { ...state, loading: false, success: null, editSuccess: true };

    case EDIT_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_CATEGORY_SUCCESS:
      return { ...state, loading: false, activeCategory: action.payload };
    case GET_CATEGORY:
      return { ...state, loading: true };

    case GET_CATEGORY_ERROR:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_CATEGORY:
      return {
        ...INITIAL_STATE,
      };

    case CLEAR_CATEGORY_SUCCESS:
      return {
        ...state,
        success: null,
      };
    case CLEAR_CATEGORY_ERROR_MESSAGES:
      return {
        ...state,
        success: null,
        error: null,
        editSuccess: null,
      };
    default:
      return state;
  }
};
