import { BrandAccount } from 'repository/brand-account';
import {
  GET_BRAND_USER,
  GET_BRAND_USER_ERROR,
  GET_BRAND_USER_SUCCESS,
  GET_BRAND_PACKAGE,
  GET_BRAND_PACKAGE_SUCCESS,
  GET_BRAND_PACKAGE_ERROR,
  SEND_REQUEST,
  SEND_REQUEST_ERROR,
  SEND_REQUEST_SUCCESS,
  SEND_REACH_BOOST_REQUEST,
  SEND_REACH_BOOST_REQUEST_SUCCESS,
  SEND_REACH_BOOST_REQUEST_ERROR,
  CLEAR_STATE,
} from './BrandAccountTypes';

interface BrandAccountState {
  loading: boolean;
  error?: any;
  data?: BrandAccount | undefined;
  didRequestSucceed?: boolean;
  didReachBoostRequestSucceed?: boolean;
  brandPackage?: any;
}

const INITIAL_STATE: BrandAccountState = {
  error: undefined,
  loading: false,
  data: undefined,
  didRequestSucceed: undefined,
  didReachBoostRequestSucceed: undefined,
  brandPackage: undefined,
};

export default (state = INITIAL_STATE, action): BrandAccountState => {
  switch (action.type) {
    case GET_BRAND_USER:
      return { loading: true };

    case GET_BRAND_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload,
      };

    case GET_BRAND_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: undefined,
      };

    case GET_BRAND_PACKAGE:
      return { loading: true };

    case GET_BRAND_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        brandPackage: action.payload,
      };

    case GET_BRAND_PACKAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: undefined,
      };

    case SEND_REQUEST:
      return { ...state, loading: true, didRequestSucceed: undefined };

    case SEND_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        didRequestSucceed: true,
      };

    case SEND_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        didRequestSucceed: false,
      };

    case SEND_REACH_BOOST_REQUEST:
      return {
        ...state,
        loading: true,
        didReachBoostRequestSucceed: undefined,
      };

    case SEND_REACH_BOOST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        didReachBoostRequestSucceed: true,
      };

    case SEND_REACH_BOOST_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        didReachBoostRequestSucceed: false,
      };

    case CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
