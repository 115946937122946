import React from 'react';
import { Checkbox } from '@material-ui/core';
import { Controller } from 'react-hook-form';

interface CheckBoxInputProps {
  name: string;
  label: string;
  formValues: any;
  style?: any;
  className?: string;
  triggerTargets?: Array<string>;
  globalTrigger?: any;
  disabled?: boolean;
}

export const CheckBoxInput = ({
  name,
  label,
  formValues,
  style,
  disabled = false,
  className = '',
  triggerTargets = [name],
  globalTrigger = () => {},
}: CheckBoxInputProps) => (
  <Controller
    control={formValues.control}
    name={name}
    render={({ onChange, value }) => (
      <div style={style}>
        <Checkbox
          onChange={(e) => {
            onChange(e.target.checked);
            formValues.setValue(name, e.target.checked);
            triggerTargets.map((target) => formValues.trigger(target));
            globalTrigger();
          }}
          inputProps={{
            disabled: disabled,
          }}
          checked={value}
          name={name}
          className={className}
        />
        {label}
      </div>
    )}
  />
);
