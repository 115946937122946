import React from 'react';
export { Account } from './Account/Account';
export const Login = React.lazy(() => import('./Login/Login'));
export const Logout = React.lazy(() => import('./Logout/Logout'));

export const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
export const NotFound = React.lazy(() => import('./NotFound/NotFound'));
export const InvalidToken = React.lazy(
  () => import('./InvalidToken/InvalidToken')
);

export const ForgotPassword = React.lazy(
  () => import('./ForgotPassword/ForgotPassword')
);
export const SetPassword = React.lazy(
  () => import('./SetPassword/SetPassword')
);

export const User = React.lazy(() => import('./User/User'));
export const BrandPages = React.lazy(() => import('./BrandPages/BrandPages'));
export const BrandPageForm = React.lazy(
  () => import('./BrandPageForm/BrandPageForm')
);

export const Coupon = React.lazy(() => import('./Coupon/Coupon'));
export const CouponForm = React.lazy(() => import('./CouponForm/CouponForm'));

export const Product = React.lazy(() => import('./Product/Product'));
export const ProductForm = React.lazy(
  () => import('./ProductForm/ProductForm')
);
export const ProductCodes = React.lazy(
  () => import('./ProductCodes/ProductCodes')
);

// injection line - do not remove or edit
export const Categories = React.lazy(() => import('./Categories/Categories'));
export const CategoryForm = React.lazy(
  () => import('./CategoryForm/CategoryForm')
);
export const Consumer = React.lazy(() => import('./Consumer/Consumer'));
export const ConsumerForm = React.lazy(
  () => import('./ConsumerForm/ConsumerForm')
);

export const ReportForm = React.lazy(() => import('./ReportForm/ReportForm'));
export const UserForm = React.lazy(() => import('./UserForm/UserForm'));

export const HeaderImages = React.lazy(() =>
  import('./HeaderImages/HeaderImages').then(({ HeaderImages }) => ({
    default: HeaderImages,
  }))
);

export const BrandUserForm = React.lazy(
  () => import('./BrandUserForm/BrandUserForm')
);

export const PriceList = React.lazy(() => import('./PriceList/PriceList'));
export const BrandUsers = React.lazy(() => import('./BrandUsers/BrandUsers'));
export const BrandAccount = React.lazy(
  () => import('./BrandAccount/BrandAccount')
);

export const AddOn = React.lazy(() => import('./AddOn/AddOn'));
export const BrandPageBanner = React.lazy(
  () => import('./BrandPageBanner/BrandPageBanner')
);
export const Information = React.lazy(
  () => import('./Information/Information')
);

export const GlobalSetting = React.lazy(
  () => import('./GlobalSetting/GlobalSetting')
)
