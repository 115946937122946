import React from 'react';
import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import './InfoButton.scss';

interface InfoButtonProps {
  onClick?: any;
  style?: any;
  alignRight?: boolean;
  className?: any;
  disabled?: boolean;
}

export const InfoButton = ({
  onClick,
  style = {},
  className = '',
  alignRight = false,
  disabled = false,
}: InfoButtonProps) => {
  return (
    <>
      {onClick && (
        <IconButton
          disabled={disabled}
          onClick={onClick}
          className={`info-button ${
            alignRight ? 'info-margin-left-auto' : 'margin-left-10'
          } ${className}`}
          style={style}
        >
          <InfoIcon />
        </IconButton>
      )}
    </>
  );
};
