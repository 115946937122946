export const CATEGORIES_START = 'categories/categories-start';
export const CATEGORIES_SUCCESS = 'categories/categories-success';
export const CATEGORIES_ERROR = 'categories/categories-error';

export const GET_CATEGORIES = 'categories/start';
export const GET_CATEGORIES_SUCCESS = 'categories/success';
export const GET_CATEGORIES_ERROR = 'categories/error';
export const CATEGORIES_PAGINATION = 'categories/pagination';

export const CATEGORY_UPDATE_LINE = 'categories/update-line';

export const START_UPLOAD = 'category/upload';
export const UPLOAD_SUCCESS = 'category/upload-success';

export const CLEAR_CATEGORY_DATA = 'categories/clear-product-data';
export const DISMISS_NOTIF = 'categories/dismiss-notification';

export const DELETE_CATEGORIES = 'categories/delete';
export const DELETE_CATEGORIES_SUCCESS = 'categories/delete_success';
export const DELETE_CATEGORIES_ERROR = 'categories/delete_error';

export const GET_ALL_CATEGORIES_MAP = 'categories/getAllMap';
export const GET_ALL_CATEGORIES_MAP_SUCCESS = 'categories/getAllMapSuccess';
export const GET_ALL_CATEGORIES_MAP_ERROR = 'categories/getAllMapError';

export const CHANGE_PAGINATION = 'categories/change-pagination';
export const CLEAR_CATEGORY = 'categories/clear-category';
