import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AdminRoutes } from 'routes/AdminRoutes';
import { Header, Sidebar } from 'components';
import { Account } from 'containers';
import { isAuth } from 'services/auth';
import * as RoleManager from 'services/role';
import { getUserProfile } from 'containers/User/UserActions'

import './AdminLayout.scss';
import APIErrorProvider from '../../components/APIErrorNotification/APIErrorProvider';
import { APIErrorNotification } from '../../components/APIErrorNotification/APIErrorNotification';
import { BlockedInfoDialog } from 'components/InfoDialog/BlockedInfoDialog';

export const AdminLayout = ({ path }) => {
  
  const dispatch = useDispatch();
  const location = useLocation();
  const userProfile = useSelector((state: any) => state.user?.userProfile);
  const [isOpen, setIsOpen] = useState(!(window.outerWidth < 1200));

  const closeSidebar = () => {
    let savedSidebar: any = localStorage.getItem('sidebar');

    if (
      window.outerWidth < 1200 ||
      (savedSidebar && !parseInt(savedSidebar, 10))
    ) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const toggleSidebar = () => {
    localStorage.setItem('sidebar', isOpen ? '0' : '1');
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    closeSidebar();
    window.addEventListener('resize', closeSidebar);

    return () => {
      window.removeEventListener('resize', closeSidebar);
    };
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [])

  useEffect(() => {

    if (!userProfile) {
      return;
    }

    if (RoleManager.isSuperAdmin()) {
      return;
    }

  }, [userProfile]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js-eu1.hs-scripts.com/145120089.js";
    script.async = true;
    script.defer = true;
    script.id = "hs-script-loader";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!isAuth()) {
    return <Redirect to="/login" />;
  }
  
  let isBlocked = !!userProfile?.isBlocked
  let messageType = 'userBlocked'

  const iframePaths = [
    '/dashboard/add-ons',
    '/dashboard/brand-page-banners',
    '/dashboard/information'
  ]

  const isIframePath = iframePaths.includes(location.pathname)

  return (
    <div className="admin">
      <React.Suspense fallback="loading..">
        <Header isOpen={isOpen} toggleSidebar={toggleSidebar} />
      </React.Suspense>
      <React.Suspense fallback="loading..">
        <Sidebar isOpen={isOpen} closeSidebar={closeSidebar} />
      </React.Suspense>

      <React.Suspense fallback="loading..">
        {isBlocked && <BlockedInfoDialog messageType={messageType} allowClose={false} />}
      </React.Suspense>

      <React.Suspense fallback="loading..">
        <div className={`content ${isOpen ? 'content--open' : ''} ${isIframePath && 'padding-0'}`}>
          <APIErrorProvider>
            <APIErrorNotification></APIErrorNotification>
            <Switch>
              {AdminRoutes.map((route: any, index) => {
                if (
                  route.allowed &&
                  RoleManager.isAbleTo(route.namespace, 'view')
                ) {
                  const routeProps = { ...route, path: path + route.path };
                  return (
                    <Route exact {...routeProps} key={route.path + index} />
                  );
                }

                return null;
              })}
            </Switch>
          </APIErrorProvider>
        </div>
      </React.Suspense>
      <Account />
    </div>
  );
};
