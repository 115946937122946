import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import LoginBackground from "../../assets/images/login_background.png";

import './IntroCard.scss';

type IntroCardProps = {
  title: string;
  subtitle: string;
  children?: any;
};

export const IntroCard: React.FC<IntroCardProps> = (props) => {
  const { title, subtitle, children } = props;
  return (
    <Grid
      className="intro-card"
      justify="center"
      alignContent="center"
      container
    >
      <Grid item xs={12} sm={10} lg={10} xl={8}>
        <Grid container justifyContent='center' alignItems='center' className="intro-container">
            <Grid item xs={12} lg={4} xl={4} className="content-section">
              {children}
            </Grid>
            <Grid item xs={12} lg={7} xl={7} style={{ textAlign: 'center' }}>
              <img
                className="login-background"
                src={LoginBackground}
                alt="banner"
                loading="lazy"
              />
            </Grid>
          </Grid>
      </Grid>
    </Grid>
  );
};
