import { LOGIN_START, LOGIN_SUCCESS, LOGIN_ERROR } from './LoginTypes';

const INITIAL_STATE = {
  loading: false,
  error: false
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOGIN_START:
      return { ...state, loading: true, error: null };

    case LOGIN_SUCCESS:
      return { ...state, loading: false };

    case LOGIN_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
