import Axios from 'axios';
import jwt from 'jsonwebtoken';
import { isAuth, logoutUser } from './auth';

const instance: any = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = isAuth();
    if (token) {
      const tokenData = jwt.decode(token, { complete: true });
      const now = new Date().getTime() / 1000;
      if (now < tokenData.payload.exp) {
        config.headers['Authorization'] = `Bearer ${token}`;
      } else {
        logoutUser();
        window.location.href = '/login';
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if ([401].indexOf(error.response.status) !== -1) {
      const isLoginReq =
        error.response.config.url.match(/authenticate/g) ||
        error.response.config.url.match(/api\/authenticate/g);

      if (!isLoginReq || !isLoginReq.length) {
        logoutUser();
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
