import {
  CATEGORIES_START,
  CATEGORIES_SUCCESS,
  CATEGORIES_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_CATEGORIES_ERROR,
  CATEGORIES_PAGINATION,
  GET_ALL_CATEGORIES_MAP,
  GET_ALL_CATEGORIES_MAP_SUCCESS,
  GET_ALL_CATEGORIES_MAP_ERROR,
  CHANGE_PAGINATION,
  CLEAR_CATEGORY,
} from './CategoriesTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  loadingMap: false,
  errorMap: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CHANGE_PAGINATION:
      return { ...state, filter: action.payload };

    case CATEGORIES_START:
      return { ...state, loading: true, error: null };

    case CATEGORIES_SUCCESS:
      return { ...state, loading: false };

    case CATEGORIES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_CATEGORIES:
      return { ...state, loading: true };

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case DELETE_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        deleteError: true,
        error: action.payload,
      };

    case GET_CATEGORIES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CATEGORIES_PAGINATION:
      return { ...state, pagination: action.payload };

    case DELETE_CATEGORIES_SUCCESS:
      return { ...state, loading: false, deleteSuccess: true };

    case GET_ALL_CATEGORIES_MAP:
      return { ...state, loadingMap: true };

    case GET_ALL_CATEGORIES_MAP_SUCCESS:
      return {
        ...state,
        loadingMap: false,
        errorMap: false,
        map: Object.keys(action.payload).map((key, index) => ({
          id: Number.parseInt(key),
          name: action.payload[key],
        })),
      };

    case GET_ALL_CATEGORIES_MAP_ERROR:
      return {
        ...state,
        loadingMap: false,
        errorMap: action.payload,
      };

    case CLEAR_CATEGORY:
      return INITIAL_STATE;

    default:
      return state;
  }
};
