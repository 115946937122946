import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';

interface CurrencyTextInputProps {
  onChange?: any;
  value?: any;
  label: string;
  decimalScale: number;
  thousandSeparator: string;
  decimalSeparator: string;
  hasFixedDecimalScale: boolean;
  isNumericString: boolean;
  disabled: boolean;
  className?: string;
}

//TODO check if onChange and value should be in props. Test it when editing coupon

export const CurrencyTextInput = ({
  onChange,
  value,
  label,
  decimalScale,
  thousandSeparator,
  decimalSeparator,
  hasFixedDecimalScale,
  isNumericString,
  className='',
  disabled = false,
  ...props
}: CurrencyTextInputProps) => {
  const [currency, setCurrency] = useState(value);
  return (
    <NumberFormat
      customInput={TextField}
      {...props}
      disabled={disabled}
      value={currency}
      fullWidth
      label={label}
      decimalScale={decimalScale}
      decimalSeparator={decimalSeparator}
      onValueChange={(target) => {
        setCurrency(target.floatValue);
        onChange(target.floatValue);
      }}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale={hasFixedDecimalScale}
      isNumericString={isNumericString}
      className={className}
      variant="outlined"
    />
  );
};
