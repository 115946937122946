export const USER_START = 'user/user-start';
export const USER_SUCCESS = 'user/user-success';
export const USER_ERROR = 'user/user-error';

export const GET_USERS = 'user/start';
export const GET_USERS_SUCCESS = 'user/success';
export const GET_USERS_ERROR = 'user/error';
export const USER_PAGINATION = 'user/pagination';

export const DELETE_USER = 'user/delete';
export const DELETE_USER_SUCCESS = 'user/delete_success';
export const DELETE_USER_ERROR = 'user/delete_error';

export const CHANGE_PAGINATION = 'user/change-pagination';

export const CLEAR_USERS = 'user/clear-users';

export const GET_USER_PROFILE = 'user/get-user-profile';
export const GET_USER_PROFILE_SUCCESS = 'user/get-user-profile-success';
export const GET_USER_PROFILE_ERROR = 'user/get-user-profile-error';
