import { createTheme } from '@material-ui/core/styles';

import './main.scss';

const primaryColor = '#008BFF';

export const AppTheme = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
    h1: {
      fontSize: '96px',
      lineHeight: '100px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '56px',
      lineHeight: '60px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '36px',
      lineHeight: '38px',
      fontWeight: 700,
    },
    h4: {
      fontSize: '30px',
      lineHeight: '32px',
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      dark: '#197BBD',
    },
    secondary: {
      main: '#000000',
    },
    info: {
      main: '#EAEAEA'
    }
  },
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: '10px',
      },
    },
    MuiButtonGroup: {
      root: {
        '&.create-btn-group': {
          '& button.MuiButton-containedSecondary': {
            color: primaryColor,
          },
        },
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#D9D9D9',
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: primaryColor,
        height: '100px',
        boxShadow: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 0,
        backgroundColor: '#000',
        padding: '15px',
      },
    },
    MuiToolbar: {
      regular: {
        height: '100px',
      },
    },
    MuiSvgIcon: {
      root: {
        '&.search-icon': {
          color: primaryColor,
        },
      },
    },
    MuiIconButton: {
      root: {
        color: primaryColor,
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          display: 'none',
          borderBottom: `2px solid #000`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
        },
      },
    },
    MuiFormLabel: {
      root: {
        textTransform: 'uppercase',
        color: '#000',
        fontWeight: 500,
        '&$focused': {
          color: '#000',
        },
      },
      filled: {
        color: '#000',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
      },
      rounded: {
        borderRadius: 0,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#000',
          color: '#FFF',

          '&:hover': {
            backgroundColor: '#000',
            color: '#FFF',
          },
        },
      },
      button: {
        '&.sidebar-item': {
          '&:hover': {
            backgroundColor: '#FAFAFA',
            color: '#000',
          },
          '& a': {
            color: '#000',
          },
          '& .item--selected': {
            color: primaryColor,
            '&:hover': {
              color: primaryColor,
            },
            '& .sidebar-icon': {
              color: primaryColor,
            },
          },
        },

        '&:hover': {
          backgroundColor: '#000',
          color: '#FFF',
        },

        '& a': {
          color: '#FFF',
          paddingLeft: '16px',
          paddingRight: '16px',
          width: '100%',
          height: '100%',

          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '25px !important',
        textTransform: 'uppercase',
        letterSpacing: '2.3px',
        padding: '19px 20px',
        background: '#F2F2F2',
        fontSize: '10px',
        '&:hover': {
          backgroundColor: 'auto',
        },
      },
      text: {
        borderRadius: '0',
        textTransform: 'uppercase',
        letterSpacing: '2.3px',
        padding: '19px 20px',
        background: '#F2F2F2',
        fontSize: '10px',
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: '#F2F2F2',
        '&:active': {
          boxShadow: 'none',
        },
        '&:hover': {
          boxShadow: 'none',
        },
      },
      sizeLarge: {
        padding: '26px 50px',
        fontSize: '13px',
        letterSpacing: '3px',
      },
      sizeSmall: {
        padding: '10px 20px',
        fontSize: '8px',
        letterSpacing: '3px',
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: `2px solid ${primaryColor}`,
        borderBottomColor: primaryColor,
        fontSize: '14px',
      },
    },
    MuiTableCell: {
      root: {
        padding: '12px',
        borderBottomColor: '#primaryColor',
        '&.minused': {
          color: primaryColor,
        },
      },
      head: {
        fontSize: '14px',
        color: '#000',
      },
    },
    MuiInputLabel: {
      shrink: {
        background: '#fff',
        paddingRight: '5px',
      },
    },
  },
});
