import React from 'react';
import { AppBar, Toolbar, IconButton, Button } from '@material-ui/core';
import { UserProfile } from 'components';
import { useTranslation } from 'react-i18next';
import Logo from 'assets/images/traffico_logo.jpeg';
import LogoSmall from 'assets/icons/logo_small.png';
import './Header.scss';

type HeaderProps = {
  isOpen?: boolean;
  toggleSidebar: Function;
};
const languages = [
  { code: 'en', name: 'EN' },
  { code: 'de', name: 'DE' },
];
export const Header: React.FC<HeaderProps> = ({
  isOpen = true,
  toggleSidebar,
}) => {
  const { i18n } = useTranslation();
  const isLargeScreen = window.outerWidth > 1000;
  return (
    <AppBar
      className={`coupon-header ${
        isOpen && isLargeScreen
          ? 'coupon-header--opened'
          : 'coupon-header--closed'
      }`}
      color="default"
      position="fixed"
    >
      <Toolbar>
        <div className="brand">
          <a href="/">
            <img
              src={isOpen ? Logo : LogoSmall}
              alt="Coupon Logo"
              className="brand-logo"
            />
          </a>
        </div>
        <div className="menu-toggle">
          <IconButton
            onClick={() => toggleSidebar()}
            className="btn-menu"
            color="inherit"
          >
            <img
              src="/images/nav-toggle.svg"
              className="nav-toggle"
              alt="Menu Toggle"
            />
          </IconButton>
        </div>
        <div className="lang-switcher">
          <div>
            {languages.map((lng) => (
              <Button
                key={lng.code}
                style={{
                  fontWeight: i18n.language === lng.code ? 'bold' : 'normal',
                }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng.code)}
              >
                {lng.name}
              </Button>
            ))}
          </div>
        </div>
        <div className="grow"></div>
        <React.Suspense fallback="loading..">
          <UserProfile />
        </React.Suspense>
      </Toolbar>
    </AppBar>
  );
};
