import React from 'react';
import { ChromePicker } from 'react-color';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import { parseRGBAObjectToString } from 'utils/helpers';

interface ColorPickerInputProps {
  control: any;
  name: string;
  isOpen: boolean;
  toggleIsOpen: any;
  rules?: any;
}

const useStyles = makeStyles({
  colorInputOverlay: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
});

export const ColorPickerInput = ({
  control,
  name,
  isOpen,
  toggleIsOpen,
  rules,
}: ColorPickerInputProps) => {
  const classes = useStyles();

  return (
    <>
      <div
        className={isOpen ? classes.colorInputOverlay : 'display-none'}
        onClick={toggleIsOpen}
      />
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ onChange, value, name }) => (
          <ChromePicker
            name={name}
            color={value}
            className={!isOpen ? 'display-none' : ''}
            onChangeComplete={(value) =>
              onChange(parseRGBAObjectToString(value.rgb))
            }
          />
        )}
      />
    </>
  );
};
