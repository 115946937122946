import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { TextField, Button, Snackbar } from '@material-ui/core';
import { Loading, InputError } from 'components';
import { emailRegex } from 'services/config';
import { useTranslation } from 'react-i18next';
import TrafficoLogo from '../../assets/images/traffico_logo.jpeg';

import './SetPasswordForm.scss';

type SetPasswordFormProps = {
  newUser: boolean;
  setPasswordState: any;
  setPassword: Function;
  clearErrors: Function;
};

export const SetPasswordForm: React.FC<SetPasswordFormProps> = ({
  newUser,
  setPasswordState,
  setPassword,
  clearErrors,
}) => {
  const { register, handleSubmit, errors, getValues } = useForm();
  const { t }: { t: any } = useTranslation();
  const { username } = useParams();

  const submitForm: any = ({ password }) => {
    setPassword({ password, token: setPasswordState?.token, newUser });
  };

  const isPasswordConfirmed = useCallback(
    function (passwordConfirm) {
      return getValues().password === passwordConfirm;
    },
    [getValues]
  );

  return (
    <div className="set-password-form-wrap">
      {setPasswordState?.loading ? <Loading /> : null}

      <img src={TrafficoLogo} className='logo' alt='traffico_logo' width={350} />
      
      {setPasswordState.success ? (
        <>
          <span className="set-success">
            <p>
              {newUser
                ? t('passwordCreatedSuccessfully')
                : t('passwordChangedSuccessfully')}
            </p>
          </span>
          <Link className="set-pass-link" to="/login">
            {t('backToLoginScreen')}
          </Link>
        </>
      ) : (
        <>
          <div className="grower"></div>
          <form onSubmit={handleSubmit(submitForm)}>
            <TextField
              inputRef={register({ required: true, pattern: emailRegex })}
              className="set-password-input"
              margin="normal"
              variant="outlined"
              size="small"
              label={t('email')}
              inputProps={{
                name: 'username',
              }}
              defaultValue={username}
              helperText={
                errors.username && (
                  <InputError message={t('validEmailIsRequired')} />
                )
              }
            />
            <TextField
              inputRef={register({ required: true })}
              className="set-password-input"
              margin="normal"
              variant="outlined"
              size="small"
              label={t('newPassword')}
              inputProps={{
                name: 'password',
              }}
              helperText={
                errors.password && (
                  <InputError message={t('validPasswordIsRequired')} />
                )
              }
            />
            <TextField
              inputRef={register({
                required: true,
                validate: isPasswordConfirmed,
              })}
              className="set-password-input"
              margin="normal"
              variant="outlined"
              size="small"
              label={t('confirmPassword')}
              inputProps={{
                name: 'passwordConfirm',
              }}
              helperText={
                errors.passwordConfirm && (
                  <InputError message={t('passwordsDontMatch')} />
                )
              }
            />

            <Button
              type="submit"
              className="set-password-button"
              fullWidth
              variant="contained"
              color="primary"
              size="small"
            >
              {newUser ? t('signUp') : t('changePassword')}
            </Button>
          </form>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={!!setPasswordState.error}
            autoHideDuration={6000}
            onClose={() => {
              clearErrors();
            }}
            message={
              <span id="message-id">
                {t(setPasswordState.error?.errorCode || 'errorOccured')}
              </span>
            }
          />
        </>
      )}
    </div>
  );
};
