export const CREATE_PRODUCT = 'products-form/create-product';
export const CREATE_PRODUCT_SUCCESS = 'products-form/create-product-success';
export const CREATE_PRODUCT_ERROR = 'products-form/create-product-error';

export const EDIT_PRODUCT = 'products-form/edit-products';
export const EDIT_PRODUCT_SUCCESS = 'products-form/edit-products-success';
export const EDIT_PRODUCT_ERROR = 'products-form/edit-products-error';

export const ACTIVATE_PRODUCT = 'products-form/activate-product';
export const ACTIVATE_PRODUCT_SUCCESS =
  'products-form/activate-product-success';
export const ACTIVATE_PRODUCT_ERROR = 'products-form/activate-product-error';

export const CREATE_AND_ACTIVATE_PRODUCT =
  'products-form/create-and-activate-product';
export const CREATE_AND_ACTIVATE_PRODUCT_SUCCESS =
  'products-form/create-and-activate-product-success';
export const CREATE_AND_ACTIVATE_PRODUCT_ERROR =
  'products-form/create-and-activate-product-error';

export const EDIT_AND_ACTIVATE_PRODUCT =
  'products-form/edit-and-activate-product';
export const EDIT_AND_ACTIVATE_PRODUCT_SUCCESS =
  'products-form/edit-and-activate-product-success';
export const EDIT_AND_ACTIVATE_PRODUCT_ERROR =
  'products-form/edit-and-activate-product-error';

export const GET_PRODUCT = 'products-form/get-product';
export const GET_PRODUCT_SUCCESS = 'products-form/get-product-success';
export const GET_PRODUCT_ERROR = 'products-form/get-product-error';

export const CLEAR_PRODUCT = 'products-form/clear-product';
export const CLEAR_PRODUCT_SUCCESS = 'products-form/clear-product-success';

export const DELETE_MEDIA = 'products-form/delete-media';
export const DELETE_MEDIA_SUCCESS = 'products-form/delete-media-success';
export const DELETE_MEDIA_ERROR = 'products-form/delete-media-error';

export const CLEAR_UPLOAD_DATA = 'products-form/clear-data';
