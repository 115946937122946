import React from 'react';
import { Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

interface DatePickerInputProps {
  control: any;
  name: string;
  label: string;
  minDateMessage?: string;
  fullWidth?: boolean;
  format?: string;
  disablePast?: boolean;
  invalidDateMessage?: string;
  maxDateMessage?: string;
  disabled?: boolean;
  rules?: any;
  hideDatePickerButton?: boolean;
  inputProps?: any;
  InputLabelProps?: any;
  variant?: any;
  size?: any;
  className?: any;
}

export const DatePickerInput = ({
  control,
  name,
  label,
  InputLabelProps,
  fullWidth = true,
  format = 'dd-MM-yyyy',
  disablePast,
  minDateMessage,
  maxDateMessage,
  invalidDateMessage,
  disabled,
  rules,
  hideDatePickerButton,
  inputProps,
  variant = 'standard',
  size,
  className
}: DatePickerInputProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        control={control}
        fullWidth={fullWidth}
        rules={rules}
        minDateMessage={minDateMessage}
        render={({ onBlur, onChange, value, ref }) => (
          <KeyboardDatePicker
            disableToolbar
            fullWidth={fullWidth}
            label={label}
            format={format}
            value={value}
            size={size}
            className={className}
            inputRef={ref}
            inputVariant={variant}
            disablePast={disablePast}
            onChange={onChange}
            invalidDateMessage={invalidDateMessage}
            maxDateMessage={maxDateMessage}
            disabled={disabled}
            clearable
            variant="dialog"
            DialogProps={{
              className: 'date-picker-dialog',
            }}
            onBlur={onBlur}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              className: hideDatePickerButton ? 'display-none' : '',
            }}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
};
