import * as actionTypes from './ProductTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case actionTypes.CHANGE_FILTER:
      return { ...state, filter: action.payload };

    case actionTypes.PRODUCT_START:
      return { ...state, loading: true, error: null };

    case actionTypes.PRODUCT_SUCCESS:
      return { ...state, loading: false };

    case actionTypes.PRODUCT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.GET_PRODUCTS:
      return { ...state, loading: true };

    case actionTypes.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.map((product) =>
          product.position ? { ...product, featured: true } : product
        ),
        count: action.payload.total,
      };

    case actionTypes.DELETE_PRODUCT_SUCCESS:
      return { ...state, loading: false, deleteSuccess: true };

    case actionTypes.GET_PRODUCTS_ERROR:
    case actionTypes.DELETE_PRODUCT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.SET_PRODUCT_FEATURED_START:
      return { ...state, loading: true, error: null };

    case actionTypes.SET_PRODUCT_FEATURED_SUCCESS:
      return { ...state, loading: false, featuredSuccess: true };

    case actionTypes.SET_PRODUCT_NOT_FEATURED_SUCCESS:
      return { ...state, loading: false, notFeaturedSuccess: true };

    case actionTypes.SET_PRODUCT_FEATURED_ERROR:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.MARK_PRODUCT_FEATURED:
      return {
        ...state,
        data: state.data.map((product) =>
          product.id === action.payload || product.position
            ? { ...product, featured: true }
            : { ...product, featured: false }
        ),
      };

    case actionTypes.CLEAR_PRODUCT: {
      return INITIAL_STATE;
    }

    case actionTypes.GET_ELIGIBLE_PRODUCTS:
      return { ...state, eligibleProducts: [], loading: true };

    case actionTypes.GET_ELIGIBLE_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        eligibleProducts: action.payload,
      };

    case actionTypes.GET_ELIGIBLE_PRODUCTS_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
