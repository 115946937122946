import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { CustomDialogTitle } from 'components';
import { useTranslation } from 'react-i18next';

export const PlayVideoModal = ({ videoType, playVideoOnAccept }) => {
  const { t }: { t: any } = useTranslation();

  const onVimeoPlayAccept = () => {
    localStorage.setItem('isVimeoPlayAccepted', 'true');
    playVideoOnAccept(true);
  };

  const onYoutubePlayAccept = () => {
    localStorage.setItem('isYoutubePlayAccepted', 'true');
    playVideoOnAccept(true);
  };

  const vimeoContent = {
    title: t('vimeoAktivierenModalTitle'),
    content: t('vimeoAktivierenModalContent'),
    type: 'vimeo',
    buttonClick: onVimeoPlayAccept,
  };

  const youtubeContent = {
    title: t('youtubeAktivierenModalTitle'),
    content: t('youtubeAktivierenModalContent'),
    type: 'youtube',
    buttonClick: onYoutubePlayAccept,
  };

  const item = videoType === 'vimeo' ? vimeoContent : youtubeContent;

  const onModalClose = () => {
    playVideoOnAccept(false);
  };

  return (
    <Dialog
      open={true}
      onClose={onModalClose}
      aria-labelledby="responsive-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="sm"
    >
      <div className="no-coupon-creations-dialog">
        <CustomDialogTitle showCloseButton={true} onClose={onModalClose}>
          {item?.title}
        </CustomDialogTitle>
        <DialogContent>
          {item.content}{' '}
          {item.type === 'vimeo' ? (
            <a href="https://vimeo.com/privacy">
              {t('datenschutzbestimmungenVimeo')}
            </a>
          ) : (
            <a href="https://policies.google.com/privacy">
              {t('datenschutzbestimmungenYouTube')}
            </a>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={item.buttonClick}
            color={'primary'}
            variant="contained"
            className="dialog-button"
          >
            {item.title}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
