import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  Button,
  Container,
  DialogActions,
  Dialog,
  Grid,
  Checkbox,
  FormLabel,
  IconButton,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InfoIcon from '@material-ui/icons/Info';
import { InputError } from 'components';
import { DatePickerInput, FormCheckBox } from 'components/FormInputs';
import useAPIError from 'components/APIErrorNotification/useAPIError';

import { ErrorMessage } from '@hookform/error-message';
import {
  formatDateToISOString,
  parseDate,
  copyTextToClipboard,
} from 'utils/helpers';
import { ReachBoostUpgradeApproval } from 'repository/brand-page';
import { ReachBoostUpgradeRequest } from 'repository/brand-account';
import * as RoleManager from 'services/role';

const useStyles = makeStyles({
  infoButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    color: '#57A3F0',
  },
  dialogTitle: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '31px',
    marginBottom: '15px',
  },
  subHeading: {
    color: '#000',
    fontSize: '16px',
    lineHeight: '19px',
  },
  smallLabel: {
    marginBottom: '0px',
    '& small': {
      fontSize: '12px',
    },
  },
  reachBoostStatus: {
    fontSize: '18px',
    verticalAlign: 'middle',
  },
  reachBoostStatusCheckbox: {
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'auto',
    },
  },
});

export interface Props {
  data: any;
  isOpen: boolean;
  submitButtonLabel?: string;
  onCloseClick(): void;
  onInfoClick(): void;
  onOKClick(
    reachBoostRequestData: ReachBoostUpgradeRequest | ReachBoostUpgradeApproval
  ): void;
}

const isIdValid = (id) => id !== 'create';

export const ReachBoostRequestDialog = ({
  data,
  isOpen,
  submitButtonLabel = 'requestChanges',
  onCloseClick,
  onOKClick,
  onInfoClick,
}: Props) => {
  const { t }: { t: any } = useTranslation();
  const classes = useStyles();
  const { id } = useParams();

  const [today] = useState(new Date());
  const [tomorrow] = useState(new Date().setDate(today.getDate() + 1));

  const { current: currentData, requested: requestedData } = data;

  const [defaultValues] = useState({
    status: requestedData ? true : false, //todo reachboost check logic behind this default value
    requestedStartDate: parseDate(requestedData?.startDate || today),
    requestedEndDate: parseDate(requestedData?.endDate || tomorrow),
  });

  const formValues = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
  });

  const { handleSubmit, errors, control, watch } = formValues;

  const statusWatcher: boolean = watch('status');
  const startDateWatcher: string = watch('requestedStartDate');
  const endDateWatcher: string = watch('requestedEndDate');
  const isCCAdmin = RoleManager.isAbleTo('brand_page', 'editReachBoost');

  const startDateValidator = (startDate) => {
    const dayInMs = 86400000;
    const startDateParsed = new Date(startDate).getTime() + dayInMs;
    const endDateParsed = new Date(endDateWatcher).getTime() + dayInMs;
    const today = new Date().getTime();

    if (startDate.toString() === 'Invalid Date') {
      return t('invalidDate');
    } else if (endDateParsed <= startDateParsed) {
      return t('startDateMustBeBeforeEndDate');
    } else if (startDateParsed < today) {
      return t('startDateMustNotBeInPast');
    } else {
      return true;
    }
  };

  const endDateValidator = (endDate) => {
    const dayInMs = 86400000;
    const endDateParsed = new Date(endDate).getTime() + dayInMs;
    const today = new Date().getTime();

    if (endDate.toString() === 'Invalid Date') {
      return t('invalidDate');
    } else if (endDateParsed < today) {
      return t('endDateMustNotBeInPast');
    } else {
      return true;
    }
  };

  const isFormDirty = useCallback(() => {
    return (
      statusWatcher !== defaultValues.status ||
      startDateWatcher !== defaultValues.requestedStartDate ||
      endDateWatcher !== defaultValues.requestedEndDate
    );
  }, [defaultValues, statusWatcher, startDateWatcher, endDateWatcher]);

  const onSubmit = async (data) => {
    onOKClick({
      status: data.status,
      startDate: formatDateToISOString(
        data.requestedStartDate,
        'T02:00:00.000Z'
      ),
      endDate: formatDateToISOString(data.requestedEndDate, 'T22:00:00.000Z'),
      ...(isCCAdmin ? { brandId: Number.parseInt(id) } : {}),
    });
  };
  return (
    <>
      <Dialog onClose={onCloseClick} open={isOpen} maxWidth="sm">
        <Container>
          <h2 className={`${classes.dialogTitle} mt-3`}>
            {t('requestReachBoost')}
          </h2>
          <IconButton onClick={onInfoClick} className={classes.infoButton}>
            <InfoIcon />
          </IconButton>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} className={`${classes.subHeading} pb-0 mt-1`}>
                {t('currentSettings').toUpperCase()}
              </Grid>
              {!data?.active && (
                <Grid item xs={12} className={`${classes.smallLabel} pb-0`}>
                  <small>{t('noActiveReachBoostCampaign')}</small>
                </Grid>
              )}
              <Grid item xs={12} className={'pb-1 pt-2'}>
                <FormLabel className={classes.reachBoostStatus}>
                  {t('active').toUpperCase()}
                </FormLabel>
                <Checkbox
                  inputProps={{
                    disabled: true,
                  }}
                  defaultChecked={data.active}
                  className={classes.reachBoostStatusCheckbox}
                />
              </Grid>
              <Grid item xs={6} className={'pt-0'}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    fullWidth={true}
                    label={t('startDate')}
                    format={'dd-MM-yyyy'}
                    value={currentData?.startDate || ''}
                    invalidDateMessage=""
                    clearable
                    variant="dialog"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      className: 'display-none',
                    }}
                    onChange={() => {}}
                    inputProps={{ disabled: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} className={'pt-0'}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    fullWidth={true}
                    label={t('endDate')}
                    format={'dd-MM-yyyy'}
                    value={currentData?.endDate || ''}
                    invalidDateMessage=""
                    clearable
                    variant="dialog"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      className: 'display-none',
                    }}
                    onChange={() => {}}
                    inputProps={{ disabled: true }}
                    InputLabelProps={{ shrink: true }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} className="mt-2">
              {RoleManager.isAbleTo('brand_page', 'editPricing') &&
              data?.active ? (
                <>
                  <TextField
                    label={t('targetUrl')}
                    fullWidth={true}
                    value={data?.current?.targetUrl}
                    InputProps={{
                      readOnly: true,
                    }}
                    margin="none"
                  />
                  <Button
                    onClick={() => {
                      copyTextToClipboard(data?.current?.targetUrl);
                    }}
                    variant="contained"
                    color="primary"
                    style={{ float: 'right' }}
                  >
                    {t('copyLink')}
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  className={`${classes.subHeading} pb-0 mt-4`}
                >
                  {t('requestedChanges').toUpperCase()}
                </Grid>
                {!data?.requested && (
                  <Grid item xs={12} className={`${classes.smallLabel} pb-0`}>
                    <small>{t('noPendingReachBoostRequest')}</small>
                  </Grid>
                )}
                <Grid item xs={12} className={'pb-1 pt-2'}>
                  <FormLabel className={classes.reachBoostStatus}>
                    {t('active').toUpperCase()}
                  </FormLabel>
                  <FormCheckBox
                    name="status"
                    // todo reachboost check logic when we have an active but do not have a pending (should the checkbox be checked or not?)
                    control={control}
                    style={{ display: 'inline' }}
                  />
                </Grid>

                <Grid item xs={6} className={'pt-0'}>
                  <DatePickerInput
                    name="requestedStartDate"
                    control={control}
                    disabled={!statusWatcher}
                    rules={{
                      required: t('startDateRequired'),
                      validate: startDateValidator,
                    }}
                    label={t('startDate')}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="requestedStartDate"
                    render={({ message }) => <InputError message={message} />}
                  />
                </Grid>
                <Grid item xs={6} className={'pt-0'}>
                  <DatePickerInput
                    name="requestedEndDate"
                    control={control}
                    disabled={!statusWatcher}
                    rules={{
                      required: t('endDateRequired'),
                      validate: endDateValidator,
                    }}
                    disablePast={true}
                    label={t('endDate')}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="requestedEndDate"
                    render={({ message }) => <InputError message={message} />}
                  />
                </Grid>
              </Grid>
              <DialogActions className={'pb-3 pt-3'}>
                <Button color="primary" className="btn" onClick={onCloseClick}>
                  {t('cancel')}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className="btn"
                  color="primary"
                  disabled={!isCCAdmin && !isFormDirty()}
                >
                  {t(submitButtonLabel)}
                </Button>
              </DialogActions>
            </form>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};
