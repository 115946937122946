import * as actionTypes from './PriceListActionTypes';

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case actionTypes.GET_PACKAGES:
      return { ...state, loading: true, data: [] };
    case actionTypes.GET_PACKAGES_SUCCESS:
      return { ...state, loading: false, data: action.payload.data };
    case actionTypes.GET_PACKAGES_ERROR:
      return { ...state, error: action.payload };
    case actionTypes.SAVE_PACKAGES:
      return { ...state, loading: true };
    case actionTypes.SAVE_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        success: true,
      };
    case actionTypes.SAVE_PACKAGES_ERROR:
      return { ...state, loading: false, error: action.payload };
    case actionTypes.CLEAR_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
