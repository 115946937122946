export const UPLOAD_CODE = 'products-codes/upload';
export const UPLOAD_CODE_SUCCESS = 'products-codes/upload-success';
export const UPLOAD_CODE_ERROR = 'products-codes/upload-error';

export const UPLOAD_CODES_CSV = 'products-codes/upload-csv';
export const UPLOAD_CODES_CSV_SUCCESS = 'products-codes/upload-csv-success';
export const UPLOAD_CODES_CSV_ERROR = 'products-codes/upload-csv-error';

export const GET_PRODUCT = 'products-codes/get-product';
export const GET_PRODUCT_SUCCESS = 'products-codes/get-product-success';
export const GET_PRODUCT_ERROR = 'products-codes/get-product-error';
export const RESET_PRODUCT = 'products-codes/reset-product';

export const GET_PRODUCT_SUBSCRIPTION_CODES =
  'products-codes/get-product-subscription-codes';

export const GET_PRODUCT_SUBSCRIPTION_CODES_SUCCESS =
  'products-codes/get-product-subscription-codes-success';

export const GET_PRODUCT_SUBSCRIPTION_CODES_ERROR =
  'products-codes/get-product-subscription-codes-error';

export const RESET_PRODUCT_SUBSCRIPTION_CODES =
  'products-codes/reset-product-subscription-codes';

export const CHANGE_FILTER = 'product-codes/change-filter';
