import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import './ConfirmSaveCouponDialog.scss';

interface ConfirmSaveCouponDialogProps {
  title?: string;
  content?: string;
  children?: any;
  isOpen: boolean;
  hasCloseButton?: boolean;
  hasConfirmButton?: boolean;
  onClose?: any;
  onConfirm?: any;
  closeLabel?: string;
  confirmLabel: string;
  imgSrc?: string;
}

export const ConfirmSaveCouponDialog = ({
  title,
  content,
  children,
  isOpen,
  hasCloseButton = true,
  hasConfirmButton = true,
  onClose,
  onConfirm,
  closeLabel = '',
  confirmLabel,
  imgSrc,
}: ConfirmSaveCouponDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth={'md'}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <div className="dialog-content-wrapper">
        <DialogContent className="pad-tb-3">
          <h1>{title}</h1>
          {content}
          {children}
          <img src={imgSrc} />
        </DialogContent>

        <DialogActions className="dialog-actions">
          <div className="button-wrapper">
            {hasCloseButton && (
              <Button
                autoFocus
                onClick={onClose}
                color={'inherit'}
                variant="contained"
                className="close-button"
              >
                {closeLabel}
              </Button>
            )}
          </div>
          <div className="button-wrapper">
            {hasConfirmButton && (
              <Button
                onClick={onConfirm}
                color="primary"
                variant="contained"
                className="confirm-button"
              >
                {confirmLabel}
              </Button>
            )}
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
};
