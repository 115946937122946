import {
  SET_PASSWORD_START,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  SET_PASSWORD_SET_TOKEN,
} from './SetPasswordTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  success: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_PASSWORD_START:
      return { ...state, loading: true, error: null };

    case SET_PASSWORD_SUCCESS:
      return { ...state, loading: false, success: true };

    case SET_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.payload };

    case SET_PASSWORD_SET_TOKEN:
      return { ...state, token: action.payload };

    default:
      return state;
  }
};
