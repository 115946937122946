import React from 'react';
import { Grid, TextField, FormLabel } from '@material-ui/core';

export const ReadOnlyTextField = ({ label, value, type }) => {
  return (
    <Grid item sm={6}>
      {label === 'date' ? (
        <FormLabel
          style={{
            fontSize: '12px',
          }}
        >
          {label}
        </FormLabel>
      ) : null}
      <TextField
        margin={'normal'}
        type={type}
        label={label === 'date' ? undefined : label}
        fullWidth={true}
        inputProps={{
          readOnly: true,
          disabled: true,
        }}
        value={value || ''}
      />
    </Grid>
  );
};
