import React from 'react';

import './PageTitle.scss';
import { Grid } from '@material-ui/core';

type PageTitleProps = {
  title: string;
  secondaryTitle?: string;
  children?: any;
};

export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  secondaryTitle,
  children,
}) => {
  return (
    <Grid container className="page-title">
      <Grid item md={6}>
        <h1>{title}</h1>
        <h1 className="secondaryTitle">{secondaryTitle}</h1>
      </Grid>
      <Grid item md={6} className="page-title__options">
        {children}
      </Grid>
    </Grid>
  );
};
