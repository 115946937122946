export const CONSUMER_START = 'consumer/consumer-start';
export const CONSUMER_SUCCESS = 'consumer/consumer-success';
export const CONSUMER_ERROR = 'consumer/consumer-error';

export const GET_CONSUMERS = 'consumer/start';
export const GET_CONSUMERS_SUCCESS = 'consumer/success';
export const GET_CONSUMERS_ERROR = 'consumer/error';
export const CONSUMER_PAGINATION = 'consumer/pagination';

export const DELETE_CONSUMER = 'consumer/delete';
export const DELETE_CONSUMER_SUCCESS = 'consumer/delete_success';
export const DELETE_CONSUMER_ERROR = 'consumer/delete_error';

export const EXPORT_CONSUMERS = 'consumer/export';
export const EXPORT_CONSUMERS_SUCCESS = 'consumer/export_success';
export const EXPORT_CONSUMERS_ERROR = 'consumer/export_error';

export const CHANGE_PAGINATION = 'consumer/change-pagination';
export const CLEAR_CONSUMER = 'consumer/clear-consumer';
