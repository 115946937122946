import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Dialog.scss'

interface Props {
  open: boolean;
  textHtml?: string;
  onCloseClick(): void;
}

export const InfoDialog = ({ open, textHtml, onCloseClick }: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Dialog
     onClose={onCloseClick} 
     open={open} 
     PaperProps={{
      style: {
        borderRadius: '10px', // Set the border radius here
      },
    }}
    >
      <DialogContent>
        <Typography
          dangerouslySetInnerHTML={{ __html: textHtml || '' }}
          style={{ fontSize: '16px', fontWeight: 500 }}
        ></Typography>
      </DialogContent>
      <DialogActions style={{ padding: '0px 20px 20px 0px' }}>
        <Button
          color="default"
          variant="contained"
          autoFocus
          onClick={onCloseClick}
          className='h-37 black-button'
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
