import {
  Logout,
  User,
  UserForm,
  NotFound,
  BrandUsers,
  BrandUserForm,
  BrandPages,
  BrandPageForm,
  BrandAccount,
  Categories,
  CategoryForm,
  // route import line - do not remove or edit
  Consumer,
  ConsumerForm,
  Coupon,
  CouponForm,
  HeaderImages,
  PriceList,
  Product,
  ProductCodes,
  ProductForm,
  ReportForm,
  AddOn,
  BrandPageBanner,
  Information,
  GlobalSetting
} from 'containers';
import AdminRouteSpec from './AdminRouteSpec';

// import * as RoleManager from 'services/role';

export const AdminRoutes: AdminRouteSpec[] = [
  {
    component: BrandAccount,
    path: 'brand-account',
    namespace: 'brand_account',
    allowed: true,
  },
  {
    component: UserForm,
    path: 'user/:id',
    namespace: 'user',
    allowed: true,
  },
  {
    component: User,
    path: 'admin-users',
    namespace: 'user',
    allowed: true,
  },
  {
    component: UserForm,
    path: 'user/create',
    namespace: 'user',
    allowed: true,
  },

  {
    component: BrandUserForm,
    path: 'brand-user/:id',
    namespace: 'brand_admin',
    allowed: true,
  },
  {
    component: BrandUserForm,
    path: 'brand-user/create',
    namespace: 'brand_admin',
    allowed: true,
  },

  {
    component: BrandUsers,
    path: 'brand-users',
    namespace: 'brand_admin',
    allowed: true,
  },

  {
    component: BrandPageForm,
    path: 'brand-pages/:id',
    namespace: 'brand_page',
    allowed: true,
  },
  {
    component: BrandPageForm,
    path: 'brand-pages/create',
    namespace: 'brand_page',
    allowed: true,
  },
  {
    component: BrandPageForm,
    path: 'brand-page',
    namespace: 'brand_page',
    allowed: true,
  },

  {
    component: BrandPages,
    path: 'brand-pages',
    namespace: 'brand_pages',
    allowed: true,
  },

  {
    component: CouponForm,
    path: 'coupons/:id',
    namespace: 'coupon',
    allowed: true,
  },
  {
    component: Coupon,
    path: 'coupons',
    namespace: 'coupon',
    allowed: true,
  },
  {
    component: Product,
    path: 'memberships',
    namespace: 'product',
    allowed: true,
  },
  {
    component: ProductForm,
    path: 'memberships/:id',
    namespace: 'product',
    allowed: true,
  },
  {
    component: ProductCodes,
    path: 'memberships/codes/:id',
    namespace: 'product',
    allowed: true,
  },
  {
    component: CouponForm,
    path: 'coupons/create',
    namespace: 'coupon',
    allowed: true,
  },
  {
    component: CouponForm,
    path: 'brand-coupons/:id',
    namespace: 'brand_coupon',
    allowed: true,
  },
  {
    component: Coupon,
    path: 'brand-coupons',
    namespace: 'brand_coupon',
    allowed: true,
  },
  {
    component: CouponForm,
    path: 'brand-coupons/create',
    namespace: 'brand_coupon',
    allowed: true,
  },
  // route injection line - do not remove or edit
  {
    component: Consumer,
    path: 'customers',
    namespace: 'consumer',
    allowed: true,
  },
  {
    component: ConsumerForm,
    path: 'customer/:id',
    namespace: 'consumer',
    allowed: true,
  },

  {
    component: Categories,
    path: 'categories',
    namespace: 'category',
    allowed: true,
  },
  {
    component: CategoryForm,
    path: 'category/:id',
    namespace: 'category',
    allowed: true,
  },
  {
    component: CategoryForm,
    path: 'category/create',
    namespace: 'category',
    allowed: true,
  },
  {
    component: HeaderImages,
    path: 'header-images',
    namespace: 'header_images',
    allowed: true,
  },

  {
    component: ReportForm,
    path: 'report',
    namespace: 'category',
    allowed: true,
  },

  {
    component: PriceList,
    path: 'price-list',
    namespace: 'category',
    allowed: true,
  },

  {
    component: GlobalSetting,
    path: 'global-setting',
    namespace: 'global_setting',
    allowed: true,
  },

  {
    component: BrandPageBanner,
    path: 'brand-page-banners',
    namespace: 'brand_page_banner',
    allowed: true,
  },

  {
    component: Information,
    path: 'information',
    namespace: 'information',
    allowed: true,
  },

  {
    component: AddOn,
    path: 'add-ons',
    namespace: 'add_on',
    allowed: true,
  },

  {
    component: Logout,
    path: 'logout',
    namespace: 'logout',
    allowed: true,
  },

  {
    component: NotFound,
    path: '*',
    namespace: 'utility',
    allowed: true,
  },
];
