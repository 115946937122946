import React from 'react';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import './InputError.scss';

type InputErrorProps = {
  message?: string;
  className?: string;
};

export const InputError: React.FC<InputErrorProps> = ({
  message,
  className,
}) => (
  <span className={`input-error ${className}`}>
    <ErrorOutlineOutlinedIcon />
    <span>{message}</span>
  </span>
);
