import * as actionTypes from './CouponTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case actionTypes.CHANGE_PAGINATION:
      return { ...state, filter: action.payload };

    case actionTypes.COUPON_START:
      return { ...state, loading: true, error: null };

    case actionTypes.COUPON_SUCCESS:
      return { ...state, loading: false };

    case actionTypes.COUPON_ERROR:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.GET_COUPONS:
      return { ...state, loading: true };

    case actionTypes.GET_COUPONS_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case actionTypes.DELETE_COUPON_SUCCESS:
      return { ...state, loading: false, deleteSuccess: true };

    case actionTypes.GET_COUPONS_ERROR:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.DELETE_COUPON_ERROR:
      return { ...state, loading: false, deleteError: action.payload };

    case actionTypes.GET_COUPONS_AS_BRAND_ADMIN:
      return { ...state, loading: true };

    case actionTypes.GET_COUPONS_AS_BRAND_ADMIN_SUCCESS:
      return { ...state, loading: false, data: action.payload };

    case actionTypes.GET_COUPONS_AS_BRAND_ADMIN_ERROR:
      return { ...state, loading: false, error: action.payload };

    case actionTypes.COUPONS_PAGINATION:
      return { ...state, pagination: action.payload };

    case actionTypes.CLEAR_COUPON:
      return INITIAL_STATE;

    default:
      return state;
  }
};
