import {
  GET_USERS,
  GET_USERS_ERROR,
  USER_PAGINATION,
  GET_USERS_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  CHANGE_PAGINATION,
  CLEAR_USERS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
} from './UserTypes';
import * as UserService from 'repository/user';

export const changeFilter = (filter) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_PAGINATION,
      payload: {
        page: filter.page,
        pageSize: filter.pageSize,
        sort: { ...filter.sort },
      },
    });
  };
};

export const getUsers = (filter = {}) => {
  return async (dispatch) => {
    dispatch({
      type: GET_USERS,
    });

    try {
      const response = await UserService.getUsers(filter);
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: response.data.data,
      });

      dispatch({
        type: USER_PAGINATION,
        payload: {
          current: response.data.number + 1,
          first: 1,
          last: response.data.total,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_USERS_ERROR,
        payload: error.response?.data,
      });
    }
  };
};

export const getUserProfile = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_USER_PROFILE,
    });
    try {
      const response = await UserService.getUserProfile();
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_USER_PROFILE_ERROR,
        payload: error.response?.data,
      });
    }
  };
};

export const deleteUser = (user) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_USER,
    });
    try {
      const response = await UserService.deleteUser(user);
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_USER_ERROR,
        payload: error.response?.data,
      });
    }
  };
};

export const clearUsers = () => async (dispatch) => {
  dispatch({ type: CLEAR_USERS });
};
