export const GET_CONSUMER = 'consumer/consumer-start';
export const GET_CONSUMER_SUCCESS = 'consumer/consumer-success';
export const GET_CONSUMER_ERROR = 'consumer/consumer-error';
export const CONSUMER_CLEAR = 'consumer/consumer-clear';

export const EDIT_CONSUMER = 'consumer/edit-consumer';
export const EDIT_CONSUMER_SUCCESS = 'consumer/edit-consumer-success';
export const EDIT_CONSUMER_ERROR = 'consumer/edit-consumer-error';

export const CLEAR_CONSUMER_ERROR_MESSAGES =
  'consumer/clear-consumer-error-messages';

export const EDIT_CONSUMER_SUBSCRIPTION = 'consumer/subscription/edit';
export const EDIT_CONSUMER_SUBSCRIPTION_SUCCESS =
  'consumer/subscription/edit-success';
export const EDIT_CONSUMER_SUBSCRIPTION_ERROR =
  'consumer/subscription/edit-error';
