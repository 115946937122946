import API from 'services/api';

export const createUser = async (props) => {
  // expected error messages from BE
  // - usernameAlreadyExists
  // - userRoleNotFound
  // - brandNotFound (if we want to create brand admin)
  // - brandRequiredForBrandAdmin = Please select brand while creating brand admin
  // - brandPackageRequiredForBrand = Please fill the brand package details for the brand
  return await API.post('/users', props);
};

export const editUser = async (id, data) => {
  // expected error messages from BE
  // - userRoleNotFound
  // - userNotFound
  // - brandNotFound (if we want to create brand admin)
  return await API.put(`/users`, {
    userId: id,
    ...data,
  });
};

export const getUser = async (id) => {
  // expected error messages from BE
  // - userNotFound (without id)
  return await API.get(`/users/${id}`);
};

export const getUserProfile = async () => {
  // expected error messages from BE
  return await API.get(`/user-details`);
}

export const deleteUser = async (id) => {
  // expected error messages from BE
  // - userNotFound
  return await API.delete(`/users/${id}`);
};

export const getUsers = async (props) => {
  const {
    pageSize = 10,
    page = 0,
    search = '',
    filters = undefined,
    sort = [{ field: 'username', dir: 'ASC' }],
  } = props;
  return await API.post('/users/list', {
    page,
    pageSize,
    sort: sort.field
      ? [{ field: sort.field, dir: sort.dir.toUpperCase() }]
      : [],
    filters,
    search,
  });
};

export const resendActivationMail = async (userId) =>
  await API.get(`/users/${userId}/reset-activation-mail`);
// expected error messages from BE
// - userNotFound
