import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Snackbar } from '@material-ui/core';
import CSS from 'csstype';
import { useTranslation } from 'react-i18next';

type UploadBoxProps = {
  onDrop?: Function;
  acceptedFiles?: string;
  maxFilesize?: number;
  hideToolbar?: boolean;
  children?: any;
  name?: string;
  control?: any;
  style?: CSS.Properties;
  className?: string;
  disabled?: boolean;
};

export const UploadBox: React.FC<UploadBoxProps> = (props) => {
  const {
    onDrop = () => {},
    acceptedFiles = '',
    maxFilesize = 10242880, // 10MB
    children,
    style,
    disabled = false,
    className = ''
  } = props;

  const [filesRejected, setFilesRejected]: any = useState(null);
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <div
        className={className}
        style={
          style ? style : className ? undefined : { display: 'inline-block' }
        }
      >
        <Dropzone
          multiple={false}
          maxSize={maxFilesize}
          accept={acceptedFiles}
          noDrag={true}
          disabled={disabled}
          onDropAccepted={(acceptedFiles) => {
            setFilesRejected(null);
            onDrop(acceptedFiles);
          }}
          onDropRejected={(filesRejected) => {
            setFilesRejected(filesRejected);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section style={{ display: 'inline-block' }}>
              <div {...getRootProps()} style={{ display: 'inline-block' }}>
                <input {...getInputProps()} />
                <Button className={'upload-chip'}>{children}</Button> 
              </div>
            </section>
          )}
        </Dropzone>

        {filesRejected ? (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={!!filesRejected}
            autoHideDuration={6000}
            onClose={() => {
              setFilesRejected(null);
            }}
            message={<span id="message-id">{t('unsupportedFile')}</span>}
          />
        ) : null}
      </div>
    </>
  );
};
