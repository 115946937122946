import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Snackbar,
  InputBase,
} from '@material-ui/core';
import { Link as MuiLink } from '@material-ui/core';
import { Loading, InputError } from 'components';
import { emailRegex } from 'services/config';
import { useTranslation } from 'react-i18next';
import TrafficoLogo from "../../assets/images/traffico_logo.jpeg"
import ArrowRight from '../../assets/images/arrow_right.svg';

import './LoginForm.scss';

type LoginFormProps = {
  login: any;
  loginUser: Function;
  clearErrors: Function;
};

export const LoginForm: React.FC<LoginFormProps> = ({
  login,
  loginUser,
  clearErrors,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const { t }: { t: any } = useTranslation();

  const submitForm: any = ({ username, password, rememberMe }) => {
    loginUser({ username, password, rememberMe });
  };

  return (
    <div className="login-form-wrap">
      {login && login.loading ? <Loading /> : null}

      <img src={TrafficoLogo} className='logo' alt='traffico_logo' />

      <div className='login-text'>
        {t('loginToTraffico')}
      </div>

      <form onSubmit={handleSubmit(submitForm)}>
        <TextField
          inputRef={register({ required: true, pattern: emailRegex })}
          className="login-input"
          size="small"
          margin="normal"
          variant="outlined"
          label={t('email')}
          inputProps={{
            name: 'username',
          }}
          helperText={
            errors.username && (
              <InputError message={t('validEmailIsRequired')} />
            )
          }
        />
        <TextField
          inputRef={register({ required: true })}
          className="login-input"
          size="small"
          margin="normal"
          type="password"
          variant="outlined"
          label={t('password')}
          inputProps={{
            name: 'password'
          }}
          helperText={
            errors.password && (
              <InputError message={t('validPasswordIsRequired')} />
            )
          }
        />
        <FormControlLabel
          className="login-checkbox"
          inputRef={register({ required: false })}
          control={
            <Checkbox
              color="primary"
              inputProps={{
                name: 'rememberMe',
              }}
            />
          }
          label={
            <span className="login-checkbox-label">{t('rememberMe')}</span>
          }
        />

        <Button
          type="submit"
          className="login-button"
          fullWidth
          variant="contained"
          size="small"
        >
          {t('login')}
        </Button>
        <Link className="forgot-link" to="/forgot-password">
          {t('forgotPassword')}
        </Link>
      </form>

      <div className='customer-label'>
        { t('notYetACustomer') }
      </div>

      <MuiLink href='https://www.traffico.de/sign-up/' className='test-now-label' underline="none">
        { t('testNow')} <img src={ArrowRight} alt='arrow_right' />
      </MuiLink>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={!!login.error}
        autoHideDuration={6000}
        onClose={() => {
          clearErrors();
        }}
        message={
          <span id="message-id">
            {t(login.error?.errorCode || 'errorOccured')}
          </span>
        }
      />
    </div>
  );
};
