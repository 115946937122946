import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { BoxWrap } from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getFeatureFlags,
  toggleGlobalReachBoost,
} from 'repository/headerImages';
import useAPIError from 'components/APIErrorNotification/useAPIError';

export const GlobalReachBoost = () => {
  const { t }: { t: any } = useTranslation();
  const { addMessage } = useAPIError();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    getFeatureFlags().then((response) => {
      setIsActive(response?.data?.reachBoost);
    });
  }, []);

  const handleChange = async () => {
    const newReachBoostStatus = !isActive;
    const successMessage = newReachBoostStatus
      ? t('globalReachBoostActivated')
      : t('globalReachBoostDeactivated');

    try {
      await toggleGlobalReachBoost(newReachBoostStatus);
      addMessage(successMessage, 'success');
      setIsActive(newReachBoostStatus);
    } catch (error) {
      addMessage(t('errorOccured'), 'error');
    }
  };

  return (
    <fieldset>
      <legend>{t('globalReachBoost')}</legend>
      <BoxWrap>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                onChange={handleChange}
                color="primary"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={t(isActive ? 'on' : 'off')}
          />
        </FormGroup>
      </BoxWrap>
    </fieldset>
  );
};
