import React from "react"

export const BrandPageIcon = React.forwardRef((props, ref) => {
  return (
    <svg id="Ebene_2" className="svg-sidebar-icon" data-name="Ebene 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 682.95 701.5">
      <g id="Character_2" data-name="Character 2">
        <path d="m560.84.24l.08.06c-10.82-1.17-21.73,1.2-31.1,6.74-21.83,9.97-43.88,20.68-65.71,32.03-21.6,10.9-42.95,22.47-64.61,34.13-.33.32-.72.56-1.15.7-22.05,12.08-43.88,24.61-65.93,37.39-21.13,12.08-42.14,24.61-63.38,37.14-.7.48-1.63.93-2.33,1.4-19.36,11.56-40.33,20.16-62.23,25.54l-3.26.7c-4.64,1.16-9.33,2.1-14.05,2.81-9.29,1.59-18.68,2.59-28.09,3.01-14.64.48-29.24,1.15-43.88,1.85-27.26,2.04-53.06,13.13-73.29,31.52-2.81,2.56-5.62,5.34-8.12,8.12-5.19,5.63-9.89,11.69-14.05,18.12-26.32,39.94-26.32,91.71,0,131.64,3.06,4.76,6.39,9.33,9.97,13.71.12,0,.22.1.22.22,3.64,4.38,7.6,8.49,11.83,12.3,20.32,18.42,46.25,29.46,73.6,31.35,1.85,0,3.71.22,5.62.22-.56,73.72,8.75,147.19,27.67,218.45,6.55,34.7,39.98,57.52,74.69,50.98,2.14-.4,4.25-.91,6.33-1.53,31.99-13.28,49.05-48.32,39.78-81.69-13.68-53.23-21.39-107.81-22.98-162.74,6.96,3.02,13.71,6.51,20.2,10.45.93.48,1.63.93,2.56,1.4,33.21,19.66,66.64,39.33,99.84,58.04,10.23,5.82,20.42,11.24,30.42,16.86,43.66,23.91,86.84,46.44,130.24,66.41,9.35,5.58,20.3,7.86,31.1,6.49,67.79-10.23,122.12-150.44,122.12-306.91S628.62,10.43,560.84.24Zm36.21,483.39v-.03c-6.28,24.69-18.74,47.38-36.21,65.93-17.98-16.2-30.61-37.5-36.21-61.05-10.57-33.63-17.98-68.1-22.19-102.99h12.63c42.08,0,76.19-34.11,76.19-76.19h0c.02-42.08-34.08-76.2-76.16-76.22h-13.12c4.11-36.33,11.67-72.24,22.66-107.23,5.64-23.47,18.27-44.68,36.21-60.82,17.41,18.6,29.86,41.27,36.21,65.93,34.98,114.95,34.98,237.7,0,352.65Z"/>
      </g>
    </svg>
  )
})

export const CouponIcon = React.forwardRef((props, ref) => {
  return (
    <svg id="Ebene_3" className="svg-sidebar-icon" data-name="Ebene 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 718.62 748.01">
      <g id="Character_3" data-name="Character 3">
        <path d="m707.03,428.46l-27.32-41.9c-4.92-7.55-4.92-17.29,0-24.84l27.32-41.9c21.57-33.13,12.19-77.48-20.94-99.04-4.24-2.76-8.75-5.06-13.47-6.86l-46.8-17.96c-8.41-3.23-14.14-11.11-14.6-20.11l-2.57-50.01c-1.91-39.49-35.47-69.95-74.96-68.04-5.13.25-10.22,1.05-15.18,2.39l-48.36,12.99c-8.7,2.34-17.97-.66-23.64-7.66l-31.48-38.9c-24.84-30.76-69.91-35.56-100.67-10.72-3.94,3.18-7.54,6.78-10.72,10.72l-31.51,38.9c-5.66,7.01-14.92,10.01-23.61,7.66l-48.39-12.99c-38.17-10.28-77.46,12.33-87.74,50.5-1.32,4.89-2.11,9.91-2.37,14.98l-2.57,50.04c-.47,8.98-6.18,16.85-14.57,20.08l-46.8,17.96c-36.93,14.12-55.42,55.5-41.3,92.43,1.81,4.74,4.12,9.26,6.89,13.51l27.29,41.9c4.91,7.56,4.91,17.31,0,24.87l-27.32,41.9c-21.57,33.15-12.19,77.51,20.96,99.08,4.23,2.75,8.74,5.05,13.45,6.86l46.8,17.96c8.39,3.23,14.1,11.1,14.57,20.08l2.57,50.04c2.03,39.5,35.7,69.87,75.2,67.84,5.04-.26,10.04-1.05,14.91-2.36l48.39-13.02c8.71-2.32,17.97.69,23.64,7.69l31.48,38.9c24.86,30.76,69.95,35.54,100.71,10.67,3.92-3.17,7.5-6.75,10.67-10.67l31.48-38.9c5.66-7,14.91-10.01,23.61-7.69l48.42,13.05c38.22,10.23,77.5-12.45,87.74-50.67,1.3-4.84,2.08-9.8,2.34-14.81l2.57-50.01c.47-9,6.19-16.88,14.6-20.11l46.8-17.96c36.93-14.12,55.42-55.5,41.3-92.43-1.8-4.7-4.09-9.2-6.83-13.42Zm-446.44-186.02c26.97,0,48.84,21.87,48.84,48.84s-21.87,48.84-48.84,48.84-48.82-21.85-48.84-48.81c0-26.98,21.86-48.85,48.84-48.87Zm-7.12,269.58c-9.54,9.54-25.01,9.55-34.55.02-9.54-9.54-9.55-25.01-.02-34.55l227.92-227.89c9.54-9.54,25-9.54,34.53,0,9.54,9.54,9.54,25,0,34.53l.06.03-227.95,227.86Zm186.26,7.18l-.09-.03c-26.97-.05-48.8-21.96-48.75-48.93.05-26.97,21.96-48.8,48.93-48.75,26.95.05,48.77,21.92,48.75,48.87-.03,26.96-21.88,48.81-48.84,48.84Z"/>
      </g>
    </svg>
  )
})

export const AddOnIcon = React.forwardRef((props, ref) => {
  return (
  <svg id="Ebene_4" className="svg-sidebar-icon" data-name="Ebene 4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 722.53 722.53">
    <g id="Character_4" data-name="Character 4">
      <path d="m361.27,0C161.74,0,0,161.74,0,361.27s161.74,361.27,361.27,361.27,361.27-161.74,361.27-361.27S560.79,0,361.27,0Zm180.97,421.59h-117.1v113.55h-127.74v-113.55h-117.1v-120.65h117.1v-113.55h127.74v113.55h117.1v120.65Z"/>
    </g>
  </svg>
  )
})

export const BrandPageBannerIcon = React.forwardRef((props, ref) => {
  return (
  <svg id="Ebene_5" className="svg-sidebar-icon" data-name="Ebene 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 722.53 722.53">
    <g id="Character_5" data-name="Character 5">
    <path d="m1047.06,0H55.95C25.07,0,.02,25.03,0,55.91v456.33c0,30.88,25.03,55.91,55.91,55.91h991.15c30.88.02,55.93-24.99,55.95-55.87V55.91c-.02-30.89-25.07-55.91-55.95-55.91ZM125.55,183.85c.05-30.12,24.44-54.53,54.55-54.6l-.04.21c30.15-.05,54.63,24.36,54.68,54.51.04,30.15-24.36,54.63-54.51,54.68s-54.63-24.36-54.68-54.51c0-.1,0-.19,0-.29Zm7.79,245.58c-9.8-10.5-9.79-26.79.02-37.27l254.68-254.89c10.76-10.56,28.04-10.4,38.6.36,10.42,10.62,10.42,27.63,0,38.25h.16l-254.89,254.89c-11.02,10.29-28.29,9.69-38.58-1.33Zm246.89,9.14c-30.15,0-54.6-24.44-54.6-54.6s24.44-54.6,54.6-54.6,54.6,24.44,54.6,54.6c-.02,30.14-24.45,54.57-54.6,54.6Zm569.88.16h-360.13c-15.08,0-27.3-12.22-27.3-27.3s12.22-27.3,27.3-27.3h360.13c15.08,0,27.3,12.22,27.3,27.3s-12.22,27.3-27.3,27.3Zm0-127.44h-360.13c-15.08,0-27.3-12.22-27.3-27.3s12.22-27.3,27.3-27.3h360.13c15.08,0,27.3,12.22,27.3,27.3s-12.22,27.3-27.3,27.3Zm0-127.44h-360.13c-15.08,0-27.3-12.22-27.3-27.3s12.22-27.3,27.3-27.3h360.13c15.08,0,27.3,12.22,27.3,27.3s-12.22,27.3-27.3,27.3Z"/>
    </g>
  </svg>
  )
})

export const InformationIcon = React.forwardRef((props, ref) => {
  return (
  <svg id="Ebene_6" className="svg-sidebar-icon" data-name="Ebene 6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 722.53 722.53">
    <g id="Character_6" data-name="Character 6">
    <path d="m362.98,0C162.51,0,0,162.51,0,362.98s162.51,362.98,362.98,362.98,362.98-162.51,362.98-362.98S563.45,0,362.98,0Zm58.3,536.44c0,30.68-24.87,55.55-55.55,55.55s-55.55-24.87-55.55-55.55v-194.6c0-30.68,24.87-55.55,55.55-55.55s55.55,24.87,55.55,55.55v194.6Zm-55.55-295.62c-30.68,0-55.55-24.87-55.55-55.55s24.87-55.55,55.55-55.55,55.55,24.87,55.55,55.55-24.87,55.55-55.55,55.55Z"/>
    </g>
  </svg>
  )
})
