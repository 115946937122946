import API from 'services/api';

export const uploadHeaderImage = async (file, page) =>
  await API.post(`/header-images/upload/${page}`, file);

export const listHeaderImages = async () => {
  return await API.post('/header-images/list', {
    page: 0,
    pageSize: 10,
    sort: [
      {
        field: 'id',
        dir: 'DESC',
      },
    ],
    search: '',
  });
};

export const getFeatureFlags = async () => {
  // expected error messages from BE
  // featureFlagNotFound
  return await API.get('/feature');
};

export const toggleGlobalReachBoost = async (reachBoost) => {
  // expected error messages from BE
  // featureFlagNotFound
  return await API.put(`/feature?reachBoost=${reachBoost}`);
};
