import React from 'react';

import { Controller } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';

interface FormCheckBoxProps {
  control: any;
  name: string;
  label?: string;
  className?: string;
  style?: any;
  disabled?: boolean;
  children?: any;
  onChangeHandler?: any;
  [key: string]: any;
}

export const FormCheckBox = ({
  control,
  name,
  label,
  className='',
  style,
  disabled = false,
  children,
  onChangeHandler,
  ...props
}: FormCheckBoxProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ value, onChange, onBlur, ref }) => (
        <>
          <div style={style} className={className}>
            <Checkbox
              {...props}
              onChange={(event) => {
                onChange(event.target.checked);
                onChangeHandler?.(event);
              }}
              onBlur={onBlur}
              checked={!!value}
              inputProps={{
                name: name,
                disabled: disabled,
              }}
              inputRef={ref}
            />
            {label}
          </div>
          {children}
        </>
      )}
    />
  );
};
