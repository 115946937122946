import * as BrandPageActionTypes from './BrandPagesTypes';

const INITIAL_STATE = {
  loading: false,
  error: false,
  loadingMap: false,
  errorMap: false,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case BrandPageActionTypes.CHANGE_PAGINATION:
      return { ...state, filter: action.payload };

    case BrandPageActionTypes.BRAND_PAGES_START:
      return { ...state, loading: true, error: null };

    case BrandPageActionTypes.BRAND_PAGES_SUCCESS:
      return { ...state, loading: false };

    case BrandPageActionTypes.BRAND_PAGES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case BrandPageActionTypes.GET_BRAND_PAGES:
      return { ...state, loading: true };

    case BrandPageActionTypes.GET_BRAND_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case BrandPageActionTypes.DELETE_BRAND_PAGE:
      return {
        ...state,
        loading: true,
      };

    case BrandPageActionTypes.DELETE_BRAND_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteSuccess: true,
      };

    case BrandPageActionTypes.DELETE_BRAND_PAGE_ERROR:
      return {
        ...state,
        loading: false,
        deleteError: true,
      };

    case BrandPageActionTypes.GET_BRAND_PAGES_ERROR:
      return { ...state, loading: false, error: action.payload };

    case BrandPageActionTypes.BRAND_PAGES_PAGINATION:
      return { ...state, pagination: action.payload };

    case BrandPageActionTypes.GET_ALL_BRAND_PAGES_MAP:
      return { ...state, loadingMap: true };

    case BrandPageActionTypes.GET_ALL_BRAND_PAGES_MAP_SUCCESS:
      return {
        ...state,
        loadingMap: false,
        errorMap: false,
        map: Object.keys(action.payload).map((key, index) => ({
          id: Number.parseInt(key),
          name: action.payload[key],
        })),
      };

    case BrandPageActionTypes.GET_ALL_BRAND_PAGES_MAP_ERROR:
      return {
        ...state,
        loadingMap: false,
        errorMap: action.payload,
      };

    default:
      return state;
  }
};
