import {
  CLEAR_PRODUCT,
  CLEAR_PRODUCT_SUCCESS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  CREATE_PRODUCT_SUCCESS,
  EDIT_PRODUCT,
  EDIT_PRODUCT_ERROR,
  EDIT_PRODUCT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_ERROR,
  GET_PRODUCT_SUCCESS,
  DELETE_MEDIA,
  DELETE_MEDIA_SUCCESS,
  DELETE_MEDIA_ERROR,
  ACTIVATE_PRODUCT,
  ACTIVATE_PRODUCT_SUCCESS,
  ACTIVATE_PRODUCT_ERROR,
  CREATE_AND_ACTIVATE_PRODUCT,
  CREATE_AND_ACTIVATE_PRODUCT_SUCCESS,
  CREATE_AND_ACTIVATE_PRODUCT_ERROR,
  EDIT_AND_ACTIVATE_PRODUCT,
  EDIT_AND_ACTIVATE_PRODUCT_SUCCESS,
  EDIT_AND_ACTIVATE_PRODUCT_ERROR,
} from './ProductFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  uploadData: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DELETE_MEDIA:
      return {
        ...state,
        loading: true,
      };

    case DELETE_MEDIA_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case DELETE_MEDIA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CREATE_PRODUCT:
      return {
        ...state,
        loading: true,
        createSuccess: false,
        editSuccess: false,
      };

    case CREATE_PRODUCT_SUCCESS:
      return { ...state, loading: false, createSuccess: true };

    case CREATE_PRODUCT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EDIT_PRODUCT:
      return {
        ...state,
        loading: true,
        createSuccess: false,
        editSuccess: false,
      };

    case EDIT_PRODUCT_SUCCESS:
      return { ...state, loading: false, editSuccess: true };

    case EDIT_PRODUCT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case ACTIVATE_PRODUCT:
      return {
        ...state,
        loading: true,
        activateProductSuccess: false,
      };

    case ACTIVATE_PRODUCT_SUCCESS:
      return { ...state, loading: false, activateProductSuccess: true };

    case ACTIVATE_PRODUCT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CREATE_AND_ACTIVATE_PRODUCT:
      return {
        ...state,
        loading: true,
        createAndActivateProductSuccess: false,
        editAndActivateProductSuccess: false,
      };

    case CREATE_AND_ACTIVATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        createAndActivateProductSuccess: true,
      };

    case CREATE_AND_ACTIVATE_PRODUCT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EDIT_AND_ACTIVATE_PRODUCT:
      return {
        ...state,
        loading: true,
        createAndActivateProductSuccess: false,
        editAndActivateProductSuccess: false,
      };

    case EDIT_AND_ACTIVATE_PRODUCT_SUCCESS:
      return { ...state, loading: false, editAndActivateProductSuccess: true };

    case EDIT_AND_ACTIVATE_PRODUCT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_PRODUCT:
      return { ...state, loading: true };

    case GET_PRODUCT_SUCCESS:
      return { ...state, loading: false, activeProduct: action.payload };

    case GET_PRODUCT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CLEAR_PRODUCT:
      return {
        ...INITIAL_STATE,
      };

    case CLEAR_PRODUCT_SUCCESS:
      return {
        ...state,
        success: null,
      };

    default:
      return state;
  }
};
