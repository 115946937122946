import React from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import { InputError } from 'components';
import { objectByString } from 'utils/helpers';

export const FormNumberFormat = ({
  name,
  label,
  rules,
  control,
  errors,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ value, onChange, onBlur, ref }) => {
        return (
          <NumberFormat
            {...rest}
            label={label}
            value={value}
            getInputRef={ref}
            customInput={TextField}
            displayType="input"
            fullWidth={true}
            thousandSeparator={true}
            decimalScale={2}
            onValueChange={(target) => {
              onBlur();
              onChange(target.floatValue);
            }}
            suffix="€ "
            helperText={
              errors &&
              objectByString(errors, name) && (
                <InputError message={objectByString(errors, name).message} />
              )
            }
          />
        );
      }}
    />
  );
};
