import { combineReducers } from 'redux';
import LoginReducer from 'containers/Login/LoginReducer';
import AccountReducer from 'containers/Account/AccountReducer';
import ForgotPasswordReducer from 'containers/ForgotPassword/ForgotPasswordReducer';
import UserReducer from 'containers/User/UserReducer';
import BrandAccountReducer from 'containers/BrandAccount/BrandAccountReducer';
// import injection line - do not remove or edit
import CategoriesReducer from 'containers/Categories/CategoriesReducer';
import CouponsReducer from 'containers/Coupon/CouponReducer';
import CouponsFormReducer from 'containers/CouponForm/CouponFormReducer';
import ConsumerReducer from 'containers/Consumer/ConsumerReducer';
import CategoryFormReducer from './containers/CategoryForm/CategoryFormReducer';
import BrandPageFormReducer from 'containers/BrandPageForm/BrandPageFormReducer';
import UserFormReducer from 'containers/UserForm/UserFormReducer';
import ConsumerFormReducer from 'containers/ConsumerForm/ConsumerFormReducer';
import SetPasswordReducer from 'containers/SetPassword/SetPasswordReducer';
import HeaderImagesReducer from 'containers/HeaderImages/HeaderImagesReducer';
import ProductReducer from 'containers/Product/ProductReducer';
import ProductFormReducer from 'containers/ProductForm/ProductFormReducer';
import ProductCodesReducer from 'containers/ProductCodes/ProductCodesReducer';
import BrandPageReducer from 'containers/BrandPages/BrandPagesReducer';
import PriceListReducer from 'containers/PriceList/PriceListReducer';
import GlobalSettingReducer from 'containers/GlobalSetting/GlobalSettingReducer';

export default combineReducers({
  login: LoginReducer,
  account: AccountReducer,
  forgotPassword: ForgotPasswordReducer,
  userForm: UserFormReducer,
  user: UserReducer,
  brandAccount: BrandAccountReducer,
  brandPageForm: BrandPageFormReducer,
  // register injection line - do not remove or edit
  categories: CategoriesReducer,
  consumer: ConsumerReducer,
  consumerForm: ConsumerFormReducer,
  categoryForm: CategoryFormReducer,
  coupons: CouponsReducer,
  couponsForm: CouponsFormReducer,
  brandCoupons: CouponsReducer,
  setPassword: SetPasswordReducer,
  headerImages: HeaderImagesReducer,
  products: ProductReducer,
  productsForm: ProductFormReducer,
  productsCodes: ProductCodesReducer,
  brandPages: BrandPageReducer,
  priceList: PriceListReducer,
  globalSetting: GlobalSettingReducer
});
