import * as brandPageActionTypes from './BrandPageFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  userError: null,
  brandPackageTemplates: null,
  brandPackage: null,
};

interface ActionInterface {
  type: string;
  payload?: any;
}

export default (state = INITIAL_STATE, action: ActionInterface) => {
  switch (action.type) {
    case brandPageActionTypes.DELETE_MEDIA:
      return {
        ...state,
        loading: true,
      };

    case brandPageActionTypes.DELETE_MEDIA_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case brandPageActionTypes.DELETE_MEDIA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case brandPageActionTypes.GET_BRAND_PAGE:
      return { ...state, loading: true };
    case brandPageActionTypes.GET_BRAND_PAGE_SUCCESS:
      const { private: priv, public: pub, ...brandPage } = action.payload;
      return {
        ...state,
        loading: false,
        activeBrandPage: {
          ...brandPage,
          ...priv,
          ...pub,
        },
      };
    case brandPageActionTypes.GET_BRAND_PAGE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case brandPageActionTypes.SAVE_BRAND_PAGE:
      return { ...state, loading: true, errorSave: null };
    case brandPageActionTypes.SAVE_BRAND_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: null,
        editSuccess: true,
      };
    case brandPageActionTypes.SAVE_BRAND_PAGE_ERROR:
      return { ...state, loading: false, errorSave: action.payload };

    case brandPageActionTypes.SAVE_BRAND_PACKAGE_SUCCESS:
      return { ...state, brandPackage: action.payload };
    case brandPageActionTypes.SAVE_BRAND_PACKAGE_ERROR:
      return { ...state, loading: false, errorSave: action.payload };

    case brandPageActionTypes.EDIT_REACH_BOOST_REQUEST:
      return { ...state, loading: true };
    case brandPageActionTypes.EDIT_REACH_BOOST_REQUEST_SUCCESS:
      return { ...state, loading: false, successReachBoost: true };
    case brandPageActionTypes.EDIT_REACH_BOOST_REQUEST_ERROR:
      return { ...state, loading: false, errorReachBoost: action.payload };

    case brandPageActionTypes.CLEAR_BRAND_PAGE:
      return {
        ...INITIAL_STATE,
        activeBrandPage: null,
      };

    case brandPageActionTypes.CLEAR_BRAND_PAGE_SUCCESS:
      return {
        ...state,
        success: null,
      };
    case brandPageActionTypes.CLEAR_BRAND_PAGE_ERROR_MESSAGES:
      return {
        ...state,
        success: null,
        error: null,
        editSuccess: null,
        errorSave: null,
        errorReachBoost: null,
        brandPackageError: null,
      };
    case brandPageActionTypes.GET_BRAND_PACKAGE_TEMPLATES_SUCCESS:
      return {
        ...state,
        brandPackageTemplates: action.payload.data,
        brandPackageError: null,
      };
    case brandPageActionTypes.GET_BRAND_PACKAGE_TEMPLATES_ERROR:
      return {
        ...state,
        brandPackageTemplates: null,
        brandPackageError: action.payload,
      };
    default:
      return state;
  }
};
