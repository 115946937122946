export const CREATE_CATEGORY = 'category-form/create-category';
export const CREATE_CATEGORY_SUCCESS = 'category-form/create-category-success';
export const CREATE_CATEGORY_ERROR = 'category-form/create-category-error';

export const EDIT_CATEGORY = 'category-form/edit-category';
export const EDIT_CATEGORY_SUCCESS = 'category-form/edit-category-success';
export const EDIT_CATEGORY_ERROR = 'category-form/edit-category-error';

export const GET_CATEGORY = 'category-form/get-category';
export const GET_CATEGORY_SUCCESS = 'category-form/get-category-success';
export const GET_CATEGORY_ERROR = 'category-form/get-category-error';

export const CLEAR_CATEGORY = 'category-form/clear-category';
export const CLEAR_CATEGORY_SUCCESS = 'category-form/clear-category-success';
export const CLEAR_CATEGORY_ERROR_MESSAGES =
  'category-form/clear-category-error-messages';

export const START_UPLOAD = 'category-form/start-upload';
export const UPLOAD_SUCCESS = 'category-form/upload-success';
export const UPLOAD_ERROR = 'category-form/upload-error';

export const DELETE_MEDIA = 'category-form/delete-media';
export const DELETE_MEDIA_SUCCESS = 'category-form/delete-media-success';
export const DELETE_MEDIA_ERROR = 'category-form/delete-media-error';
