export const SAVE_BRAND_PAGE = 'brandpage-form/save-brandpage';
export const SAVE_BRAND_PAGE_ERROR = 'brandpage-form/save-brandpage_error';
export const SAVE_BRAND_PAGE_SUCCESS = 'brandpage-form/save-brandpage_success';

export const SAVE_BRAND_PACKAGE_SUCCESS =
  'brandpagepackage-form/save-brandpage_success';
export const SAVE_BRAND_PACKAGE_ERROR =
  'brandpagepackage-form/save-brandpage_error';

export const EDIT_REACH_BOOST_REQUEST = 'brandpage-form/edit-reach-boost';
export const EDIT_REACH_BOOST_REQUEST_SUCCESS =
  'brandpage-form/edit-reach-boost-success';
export const EDIT_REACH_BOOST_REQUEST_ERROR =
  'brandpage-form/edit-reach-boost-error';

export const GET_BRAND_PAGE = 'brandpage-form/get-brandpage';

export const GET_BRAND_PAGE_ERROR = 'brandpage-form/get-brandpage-error';
export const GET_BRAND_PAGE_SUCCESS = 'brandpage-form/get-brandpage-success';

export const CLEAR_BRAND_PAGE = 'brandpage-form/clear-brandpage';
export const CLEAR_BRAND_PAGE_ERROR_MESSAGES =
  'brandpage-form/clear-brandpage-error-messages';
export const CLEAR_BRAND_PAGE_SUCCESS =
  'brandpage-form/clear-brandpage-success';

export const DELETE_MEDIA = 'brandpage-form/delete-media';
export const DELETE_MEDIA_SUCCESS = 'brandpage-form/delete-media-success';
export const DELETE_MEDIA_ERROR = 'brandpage-form/delete-media-error';

export const GET_BRAND_PACKAGE_TEMPLATES_SUCCESS =
  'brandpage-form/get-packagetemplates-success';
export const GET_BRAND_PACKAGE_TEMPLATES_ERROR =
  'brandpage-form/get-packagetemplates-error';
