import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { CustomDialogTitle } from './CustomDialogTitle';

const useStyles = makeStyles({
  dialog: {
    '&>.MuiDialog-container': {
      '&>.MuiPaper-root': {
        borderRadius: '10px',
        '&>.MuiDialogContent-root': {
          border: "none",
          fontSize: '16px',
          fontWeight: 500,
        },
        '&>.MuiDialogActions-root': {
          padding: '16px 24px'
        }
      }
    },
  }
});

interface CustomDialogProps {
  title?: string;
  content?: string;
  children?: any;
  isOpen: boolean;
  hasCloseButton?: boolean;
  hasConfirmButton?: boolean;
  hasTopRightCloseButton?: boolean;
  onClose?: any;
  onConfirm?: any;
  closeLabel?: string;
  confirmLabel: string;
}

export const CustomDialog = ({
  title,
  content,
  children,
  isOpen,
  hasCloseButton = true,
  hasConfirmButton = true,
  hasTopRightCloseButton = false,
  onClose,
  onConfirm,
  closeLabel = '',
  confirmLabel,
}: CustomDialogProps) => {

  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.dialog}
    >
      {title && (
        <CustomDialogTitle
          showCloseButton={hasTopRightCloseButton}
          onClose={onClose}
        >
          {title}
        </CustomDialogTitle>
      )}
      <DialogContent dividers className="pad-tb-3">
        {content}
        {children}
      </DialogContent>
      <DialogActions>
        {hasCloseButton && (
          <Button
            autoFocus
            onClick={onClose}
            color={'inherit'}
            variant="contained"
            className="cancel-button"
          >
            {closeLabel}
          </Button>
        )}
        {hasConfirmButton && (
          <Button onClick={onConfirm} color="primary" variant="contained" className="submit-button primary-button">
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
