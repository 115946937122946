export const CREATE_USER = 'user-form/create-coupon';
export const CREATE_USER_SUCCESS = 'user-form/create-user-success';
export const CREATE_USER_ERROR = 'user-form/create-user-error';

export const EDIT_USER = 'user-form/edit-category';
export const EDIT_USER_SUCCESS = 'user-form/edit-user-success';
export const EDIT_USER_ERROR = 'user-form/edit-user-error';

export const GET_USER = 'user-form/get-category';
export const GET_USER_SUCCESS = 'user-form/get-user-success';
export const GET_USER_ERROR = 'user-form/get-user-error';

export const CLEAR_USER = 'user-form/clear-user';
export const CLEAR_USER_SUCCESS = 'user-form/clear-coupon-success';

export const CLEAR_USER_ERROR_MESSAGES = 'user-form/clear-user-error-messages';

export const RESEND_ACTIVATION = 'user-form/resend-activation-mail';
export const RESEND_ACTIVATION_SUCCESS =
  'user-form/resend-activation-mail-success';
export const RESEND_ACTIVATION_ERROR = 'user-form/resend-activation-mail-error';
