import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import * as RoleManager from 'services/role';
import PeopleIcon from '@material-ui/icons/People';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import EuroIcon from '@material-ui/icons/Euro';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TextureIcon from '@material-ui/icons/Texture';
import CategoryIcon from '@material-ui/icons/Category';
import SettingIcon from '@material-ui/icons/Settings';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ImageIcon from '@material-ui/icons/Image';
import PaymentIcon from '@material-ui/icons/Payment';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { BrandPageIcon, CouponIcon, AddOnIcon, BrandPageBannerIcon, InformationIcon } from './Icons';
import './Sidebar.scss';
import { useTranslation } from 'react-i18next';

type SidebarProps = {
  isOpen: boolean;
  closeSidebar: Function;
};

export const Sidebar: React.FC<SidebarProps> = ({ isOpen, closeSidebar }) => {
  const { t }: { t: any } = useTranslation();
  
  return (
    <Drawer
      className={`sidebar ${isOpen ? 'sidebar--open' : 'sidebar--closed'}`}
      anchor="left"
      open={true}
      variant="persistent"
    >
      <List
        className="sidebar-list"
        onClick={() => {
          closeSidebar();
        }}
      >
        <List component="div" disablePadding>
          {RoleManager.isAbleTo('brand_account', 'view') && (
            <ListItem button className="sidebar-item">
              <NavLink
                exact
                to="/dashboard/brand-account"
                activeClassName="item--selected"
                style={{ alignItems: 'center' }}
              >
                <ListItemIcon>
                  <HomeRoundedIcon style={{ fontSize: 32, width: '3rem' }} className="svg-sidebar-icon" />
                </ListItemIcon>
                <ListItemText primary={t('home')} />
              </NavLink>
            </ListItem>
          )}
        </List>

        {RoleManager.isAbleTo('admin_menu', 'view') && (
          <ListItem className="sidebar-item main-list-item">
            <NavLink
              exact
              to="/dashboard/admin-users"
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title="Admin panel">
                  <Brightness1Icon className="sidebar-icon" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('adminPanel')} />
            </NavLink>
          </ListItem>
        )}
        <List component="div" disablePadding>
          {RoleManager.isAbleTo('user', 'view') && (
            <ListItem
              button
              className="sidebar-item"
              style={{ paddingLeft: 30 }}
            >
              <NavLink
                exact
                to="/dashboard/admin-users"
                activeClassName="item--selected"
              >
                <ListItemIcon>
                  <Tooltip placement="right" title={t('ccAdmins')}>
                    <PeopleIcon className="sidebar-icon" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t('ccAdmins')} />
              </NavLink>
            </ListItem>
          )}

          {RoleManager.isAbleTo('category', 'view') && (
            <ListItem
              button
              className="sidebar-item"
              style={{ paddingLeft: 30 }}
            >
              <NavLink
                exact
                to="/dashboard/categories"
                activeClassName="item--selected"
              >
                <ListItemIcon>
                  <Tooltip placement="right" title="Categories">
                    <CategoryIcon className="sidebar-icon" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t('categories')} />
              </NavLink>
            </ListItem>
          )}

          {RoleManager.isAbleTo('header_images', 'view') && (
            <ListItem
              button
              className="sidebar-item"
              style={{ paddingLeft: 30 }}
            >
              <NavLink
                exact
                to="/dashboard/header-images"
                activeClassName="item--selected"
              >
                <ListItemIcon>
                  <Tooltip placement="right" title="Header Images">
                    <ImageIcon className="sidebar-icon" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t('headerImages')} />
              </NavLink>
            </ListItem>
          )}

          {RoleManager.isAbleTo('global_setting', 'view') && (
            <ListItem
              button
              className="sidebar-item"
              style={{ paddingLeft: 30 }}
            >
              <NavLink
                exact
                to="/dashboard/global-setting"
                activeClassName="item--selected"
              >
                <ListItemIcon>
                  <Tooltip placement="right" title="Header Images">
                    <SettingIcon className="sidebar-icon" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t('globalSetting')} />
              </NavLink>
            </ListItem>
          )}

          {RoleManager.isAbleTo('product', 'view') && (
            <ListItem
              button
              className="sidebar-item"
              style={{ paddingLeft: 30 }}
            >
              <NavLink
                exact
                to="/dashboard/memberships"
                activeClassName="item--selected"
              >
                <ListItemIcon>
                  <Tooltip placement="right" title="CC Memberships">
                    <PaymentIcon className="sidebar-icon" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t('products')} />
              </NavLink>
            </ListItem>
          )}

          {RoleManager.isAbleTo('price_list', 'view') && (
            <ListItem
              button
              className="sidebar-item"
              style={{ paddingLeft: 30 }}
            >
              <NavLink
                exact
                to="/dashboard/price-list"
                activeClassName="item--selected"
              >
                <ListItemIcon>
                  <Tooltip placement="right" title="Price list">
                    <PaymentIcon className="sidebar-icon" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t('priceList')} />
              </NavLink>
            </ListItem>
          )}

          {RoleManager.isAbleTo('category', 'view') && (
            <ListItem
              button
              className="sidebar-item"
              style={{ paddingLeft: 30 }}
            >
              <NavLink
                exact
                to="/dashboard/report"
                activeClassName="item--selected"
              >
                <ListItemIcon>
                  <Tooltip placement="right" title="Reports">
                    <AssessmentIcon className="sidebar-icon" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary={t('reports')} />
              </NavLink>
            </ListItem>
          )}
        </List>

        {RoleManager.isAbleTo('consumer', 'view') && (
          <ListItem button className="sidebar-item">
            <NavLink
              exact
              to="/dashboard/customers"
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title="Customers">
                  <BusinessCenterIcon className="sidebar-icon" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('customers')} />
            </NavLink>
          </ListItem>
        )}

        {RoleManager.isAbleTo('brand_users', 'view') && (
          <ListItem button className="sidebar-item">
            <NavLink
              exact
              to="/dashboard/brand-users"
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title="Brand users">
                  <PaymentIcon className="sidebar-icon" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('brandUsers')} />
            </NavLink>
          </ListItem>
        )}

        {RoleManager.isAbleTo('brand_pages', 'view') ? (
          <ListItem button className="sidebar-item">
            <NavLink
              exact
              to="/dashboard/brand-pages"
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title={t('brandPage')}>
                  <BrandPageIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('brandPages')} />
            </NavLink>
          </ListItem>
        ) : (
          <ListItem button className="sidebar-item">
            <NavLink
              exact
              to={`/dashboard/brand-page`}
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title={t('brandPage')}>
                  <BrandPageIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('brandPage')} />
            </NavLink>
          </ListItem>
        )}

        {RoleManager.isAbleTo('coupon', 'view') && (
          <ListItem button className="sidebar-item">
            <NavLink
              exact
              to="/dashboard/coupons"
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title="Coupons">
                  <ReceiptIcon className="sidebar-icon" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('coupons')} />
            </NavLink>
          </ListItem>
        )}

        {RoleManager.isAbleTo('brand_coupon', 'view') && (
          <ListItem button className="sidebar-item">
            <NavLink
              exact
              to="/dashboard/brand-coupons"
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title="Coupons">
                  <CouponIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('coupons')} />
            </NavLink>
          </ListItem>
        )}

        {RoleManager.isAbleTo('add_ons', 'view') && (
          <ListItem button className="sidebar-item">
            <NavLink
              exact
              to="/dashboard/add-ons"
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title="Add Ons">
                  <AddOnIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('addOns')} />
            </NavLink>
          </ListItem>
        )}

        {RoleManager.isAbleTo('brand_page_banner', 'view') && (
          <ListItem button className="sidebar-item">
            <NavLink
              exact
              to="/dashboard/brand-page-banners"
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title="Add Ons">
                  <BrandPageBannerIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('brandPageBanner')} />
            </NavLink>
          </ListItem>
        )}

      {RoleManager.isAbleTo('information', 'view') && (
          <ListItem button className="sidebar-item">
            <NavLink
              exact
              to="/dashboard/information"
              activeClassName="item--selected"
            >
              <ListItemIcon>
                <Tooltip placement="right" title="Information">
                  <InformationIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText primary={t('information')} />
            </NavLink>
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};
