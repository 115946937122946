import React from 'react';
import { Controller } from 'react-hook-form';
import { Select, MenuItem } from '@material-ui/core';

interface SelectMenuProps {
  control: any;
  rules: any;
  selectOptions: any;
  name: string;
  itemKeyProperty: string;
  itemValueProperty: string;
  itemNameProperty: string;
  fullWidth?: boolean;
  disabled?: boolean;
  customOnChange?: any;
  variant?: any;
  className?: any;
}

export const SelectMenu = ({
  control,
  name,
  rules,
  fullWidth = true,
  selectOptions,
  itemKeyProperty,
  itemValueProperty,
  itemNameProperty,
  disabled = false,
  variant = 'standard',
  className = '',
  customOnChange = () => {},
}: SelectMenuProps) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ value, onChange }) => (
      <Select
        fullWidth={fullWidth}
        disabled={disabled}
        value={value}
        variant={variant}
        className={className}
        onChange={(event) => {
          onChange(event);
          customOnChange(event);
        }}
      >
        {selectOptions?.map((item) => (
          <MenuItem key={item[itemKeyProperty]} value={item[itemValueProperty]}>
            {item[itemNameProperty]}
          </MenuItem>
        ))}
      </Select>
    )}
  />
);
