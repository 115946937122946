import React from 'react';
import { Controller } from 'react-hook-form';

import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { UploadBox } from 'components';

interface UploadFormFieldProps {
  name: string;
  control: any;
  rules: any;
  acceptedFiles: any;
  onDrop: any;
  buttonLabel: string;
  maxFileSize?: number;
  showDeleteButton?: boolean;
  onDelete?: any;
  children: any;
}

export const UploadFormField = ({
  name,
  control,
  rules,
  acceptedFiles,
  onDrop,
  buttonLabel,
  maxFileSize = 10242880,
  showDeleteButton = false,
  onDelete = () => {},
  children,
}: UploadFormFieldProps) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    as={
      <>
        <UploadBox
          acceptedFiles={acceptedFiles}
          onDrop={onDrop}
          maxFilesize={maxFileSize}
          className='upload-box'
        >
          {buttonLabel}
        </UploadBox>
        {children}
        {showDeleteButton && (
          <IconButton aria-label="delete" className='delete-icon' onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </>
    }
  />
);
