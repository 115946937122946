import { SET_ACCOUNT } from './AccountTypes';

const INITIAL_STATE = {
  user: null
};

export default (state: any = INITIAL_STATE, action: any) => {
  if (action.type === SET_ACCOUNT) {
    return { ...state, user: action.payload };
  }

  return state;
};
