import {
  CLEAR_USER,
  CLEAR_USER_SUCCESS,
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  EDIT_USER,
  EDIT_USER_ERROR,
  EDIT_USER_SUCCESS,
  GET_USER,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
  CLEAR_USER_ERROR_MESSAGES,
  RESEND_ACTIVATION,
  RESEND_ACTIVATION_SUCCESS,
  RESEND_ACTIVATION_ERROR,
} from './UserFormTypes';

const INITIAL_STATE = {
  error: null,
  loading: false,
  isDone: true,
  userError: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_USER:
      return { ...state, loading: true, error: null };

    case CREATE_USER_SUCCESS:
      return { ...state, loading: false, success: true };

    case CREATE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case EDIT_USER:
      return { ...state, loading: true, error: null };

    case EDIT_USER_SUCCESS:
      return { ...state, loading: false, success: null, editSuccess: true };

    case EDIT_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_USER:
      return { ...state, loading: true };

    case GET_USER_SUCCESS:
      return { ...state, loading: false, activeUser: action.payload };

    case GET_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CLEAR_USER:
      return {
        ...INITIAL_STATE,
      };

    case CLEAR_USER_SUCCESS:
      return {
        ...state,
        success: null,
      };
    case CLEAR_USER_ERROR_MESSAGES:
      return {
        ...state,
        success: null,
        error: null,
        editSuccess: null,
      };

    case RESEND_ACTIVATION:
      return { ...state, loading: true, error: null };

    case RESEND_ACTIVATION_SUCCESS:
      return { ...state, loading: false, success: true };

    case RESEND_ACTIVATION_ERROR:
      return { ...state, loading: false, activationError: action.payload };

    default:
      return state;
  }
};
