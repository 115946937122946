import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import './Loading.scss';

interface Props {
  isCenter?: boolean
}

export const Loading = ({ isCenter = false }: Props) => (
  <div className={`loading-wrapper ${isCenter && 'position-center'}`}>
    <div className="loading-bar">
      <CircularProgress />
    </div>
  </div>
);
