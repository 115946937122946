import React from 'react';
import ReactQuill from 'react-quill';
import { Controller } from 'react-hook-form';

import 'react-quill/dist/quill.snow.css';
import './RichTextInput.scss';

export const RichTextInput = ({
  name,
  control,
  validate,
  onChangeHandler,
  theme,
  style,
  readOnly = false,
}) => (
  <Controller
    name={name}
    control={control}
    rules={{ validate: validate }}
    render={({ onChange, onBlur, value, ref }) => (
      <ReactQuill
        ref={ref}
        theme={theme}
        style={style}
        value={value?.toString() || ''}
        onChange={onChangeHandler(onChange)}
        onBlur={onBlur}
        readOnly={readOnly}
        className='quill-editor'
      />
    )}
  />
);
