import useAPIError from './useAPIError';
import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';

import './APIErrorNotification.scss';

export const APIErrorNotification = () => {
  const { error, removeError } = useAPIError();

  const handleSubmit = () => {
    removeError();
  };
  const [snackOpen, setSnack] = useState(false);

  useEffect(() => {
    if (error && error.message) {
      setSnack(true);
    }
    return () => {};
  }, [error]);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={snackOpen}
      onClose={() => {
        setSnack(false);
        handleSubmit();
      }}
      autoHideDuration={3000}
      message={<span> {error?.message} </span>}
      className={`APIErrorNotification ${error?.status}`}
    />
  );
};
